import React from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'

import PageWrapper from '../core/modules/pageWrapper'
import Width from '../core/elements/width'
import { useDocumentsApi } from '../APIs/useApi'
import { innerHtml } from '../core/utils/useInnerHTML'
import Loader from '../core/elements/loader'

const DocumentsWrapper = styled.div`
  .contentArea {
    padding: 60px 0;

    a {
      color: #097490;
    }
  }
`

const DocumentsPage = () => {
    const { data, isLoading, isError } = useDocumentsApi('/api/v2/pages/9/')
    const containerReference = React.useRef()

    if (isLoading) return <Loader />
    if (isError) return null

    return (
        <DocumentsWrapper ref={containerReference}>
            <MetaTags>
                {data?.meta && (
                    <title>
                        {data.meta.seo_title ? data.meta.seo_title : 'documenten'} - RAMM
            Funds
                    </title>
                )}
                {data?.meta?.search_description && (
                    <meta name="description" content={data.meta.search_description} />
                )}
            </MetaTags>
            <PageWrapper>
                <Width className="contentArea">
                    {innerHtml(data.body[0].value['right_column_body'])}
                </Width>
            </PageWrapper>
        </DocumentsWrapper>
    )
}
export default DocumentsPage
