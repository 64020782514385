import React from 'react'
import useFetchAppState from '../core/utils/api/useFetchAppState'
// import { useHistory } from "react-router-dom";

const url = process.env.REACT_APP_URL || `https://${window.location.host}`

// Hook
const useApi = (passedkey, passedApi) => {
    const [key, setKey] = React.useState(passedkey)
    const [api, setApi] = React.useState(passedApi)

    React.useEffect(() => {
        setApi(passedApi)
        setKey(passedkey)
    }, [passedApi, passedkey])

    // Payload is the API State response, it has the data as well as
    // functions to perform GET, PUT, PUSH, DELETE requests for this endpoint
    const payload = useFetchAppState(key, `${url}${api}`)
    return payload
}
export default useApi

// Hook
export const useHomeApi = (api) => {
    const payload = useApi('home', `${api}`)
    return payload
}

export const useAboutApi = (api) => {
    const payload = useApi('about', `${api}`)
    return payload
}

export const useParticipationApi = (api) => {
    const payload = useApi('participation', `${api}`)
    return payload
}

export const usePerformanceApi = (api) => {
    const payload = useApi('performance', `${api}`)
    return payload
}

export const useBlogApi = (api) => {
    const payload = useApi('blog', `${api}`)
    return payload
}

export const useBlogArticlesApi = (api) => {
    const payload = useApi('blogArticles', `${api}`)
    return payload
}

export const useBlogArticlesApiPerPage = (nr, api) => {
    const payload = useApi(`blogArticles-page${nr}`, `${api}`)
    return payload
}

export const useBlogArticleApi = (key, api) => {
    const payload = useApi(key, `${api}`)
    return payload
}

export const useContactApi = (api) => {
    const payload = useApi('contact1', `${api}`)
    return payload
}

export const usePrivacyApi = (api) => {
    const payload = useApi('privacy', `${api}`)
    return payload
}

export const useDocumentsApi = (api) => {
    const payload = useApi('documents', `${api}`)
    return payload
}
export const useFAQApi = (api) => {
    const payload = useApi('faq', `${api}`)
    return payload
}

export const useDocumentApi = (nr, api) => {
    const payload = useApi(`document-${nr}`, `${api}`)
    return payload
}

export const useContactFormApi = (api) => {
    const payload = useApi(`contactForm`, `${api}`)
    return payload
}

export const useBrochureFormApi = (api) => {
    const payload = useApi(`brochureForm`, `${api}`)
    return payload
}
