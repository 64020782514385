import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import FormWrapper from '../../views/form.views'
import { StepStyling, H3, Button } from '../../views/step.views'
import { Field } from '../../../../elements/inputs'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const Step = ({ goToStep, text, handleFormUpdate }) => {
  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  })
  const canContinue =
    state.firstName.length > 2 &&
    state.lastName.length > 2 &&
    state.email.length > 2 &&
    state.phone.length > 5 &&
    validateEmail(state.email)

  const updateState = (field, value) => {
    setState({
      ...state,
      [field]: value,
    })
    handleFormUpdate(
      {
        ...state,
        [field]: value,
      },
      'person2'
    )
  }
  return (
    <StepStyling>
      <div className="contentArea">
        <H3> {text.title} </H3>
        <FormWrapper>
          <div className="field">
            <label> {text.fields.firstName} </label>
            <Field
              placeholder={text.fieldsPlaceholders.firstName}
              value={state.firstName}
              onChange={(e) => updateState('firstName', e.target.value)}
            />
          </div>

          <div className="field">
            <label> {text.fields.lastName} </label>
            <Field
              placeholder={text.fieldsPlaceholders.lastName}
              value={state.lastName}
              onChange={(e) => updateState('lastName', e.target.value)}
            />
          </div>

          <div className="field">
            <label> {text.fields.email} </label>
            <Field
              placeholder={text.fieldsPlaceholders.email}
              value={state.email}
              onChange={(e) => updateState('email', e.target.value)}
            />
            {state.email.length > 2 && !validateEmail(state.email) && (
              <div> Niet geldig </div>
            )}
          </div>

          <div className="field">
            <label> {text.fields.phone} </label>
            <Field
              placeholder={text.fieldsPlaceholders.phone}
              value={state.phone}
              onChange={(e) => updateState('phone', e.target.value)}
            />
          </div>
        </FormWrapper>
        <Button
          notFullWidth
          fade={!canContinue}
          handleClick={() => canContinue && goToStep(4)}
        >
          {' '}
          {text.button}{' '}
        </Button>

        {text.tip && text.tip.length > 0 && (
          <div className="textArea tiparea">
            <FontAwesomeIcon icon={faInfoCircle} />
            {text.tip}
          </div>
        )}
      </div>
    </StepStyling>
  )
}
export default Step
