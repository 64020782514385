import styled from 'styled-components'

export const XssTPadding = styled.div`
  padding: 6px 0;

  @media screen and (min-width: 1900px) {
    padding: calc(6px + 0.14vw) 0;
  }
`

export const XssPadding = styled.div`
  padding: 6px 12px;

  @media screen and (min-width: 1900px) {
    padding: calc(6px + 0.14vw) calc(12px + 0.14vw);
  }
`

export const MetricsPadding = styled.div`
  padding: 3px 12px;

  @media screen and (min-width: 1900px) {
    padding: calc(3px + 0.14vw) calc(12px + 0.14vw);
  }

  @media screen and (max-width: 940px) {
    padding: 3px 10px;
  }
`

export const MetricsInnerPadding = styled.div`
  padding: 3px 12px;

  @media screen and (min-width: 1900px) {
    padding: calc(3px + 0.14vw) calc(12px + 0.14vw);
  }

  @media screen and (max-width: 940px) {
    padding: 3px 0;
    &:first-child {
      padding-right: 10px;
    }
    &:last-child {
      padding-left: 10px;
    }
  }
`

export const XssTLPadding = styled.div`
  padding: 6px 0 6px 24px;

  @media screen and (min-width: 1900px) {
    padding: calc(6px + 0.14vw) 0 calc(6px + 0.14vw) calc(24px + 0.14vw);
  }
`

export const MenuPadding = styled.div`
  padding: 38px 0;

  @media screen and (min-width: 1900px) {
    padding: calc(38px + 0.14vw) 0;
  }

  @media (max-width: 940px) {
    padding: 18px 0;
  }
`

export const InformationPadding = styled.div`
  padding: 100px 0;

  @media screen and (min-width: 1900px) {
    padding: calc(100px + 0.14vw) 0;
  }

  @media (max-width: 720px) {
    padding: 20px 0 40px;
  }
`

export const MenuItemPadding = styled.div`
  padding: 24px 12px;

  @media screen and (min-width: 1900px) {
    padding: calc(24px + 0.14vw) calc(12px + 0.14vw);
  }
`

export const MobileMenuItemPadding = styled.div`
  padding: 24px 0;
`

export const BigButtonPadding = styled.div`
  padding: 18px 26px 14px;

  @media screen and (min-width: 1900px) {
    padding: calc(18px + 0.14vw) calc(26px + 0.14vw) calc(14px + 0.14vw);
  }
`

export const ScrollButtonPadding = styled.div`
  padding: 18px 26px 14px;

  @media screen and (min-width: 1900px) {
    padding: calc(18px + 0.14vw) calc(26px + 0.14vw) calc(14px + 0.14vw);
  }
`

export const SmallButtonPadding = styled.div`
  padding: 12px 16px 8px;

  @media screen and (min-width: 1900px) {
    padding: calc(12px + 0.14vw) calc(16px + 0.14vw) calc(8px + 0.14vw);
  }
`

export const BigButtonIconPadding = styled.div`
  padding: 16px;

  @media screen and (min-width: 1900px) {
    padding: calc(16px + 0.14vw);
  }

  @media screen and (max-width: 940px) {
    padding: 10px 14px;
  }
`

export const FooterPaddingBlock = styled.div`
  padding: 40px 0px;

  @media screen and (min-width: 1900px) {
    padding: calc(40px + 0.14vw) 0;
  }

  @media screen and (max-width: 940px) {
    padding: 34px 0;
  }
`

export const FooterFormPaddingBlock = styled.div`
  padding: 0px 0 0 0;

  @media screen and (min-width: 1900px) {
  }
`

export const FooterBlockPadding = styled.div`
  padding: 10px 0;

  @media screen and (min-width: 1900px) {
    padding: calc(10px + 0.14vw);
  }
`

export const FooterSubscribeBlockPadding = styled.div`
  padding: 0 200px;

  @media screen and (min-width: 1900px) {
    padding: 0 calc(200px + 0.14vw);
  }

  @media (max-width: 920px) {
    padding: 0;
  }
`

export const HeaderMenuButtonPadding = styled.div`
  padding: 10px 0 0 12px;

  @media screen and (min-width: 1900px) {
    padding: calc(10px + 0.14vw) 0 0 calc(12px + 0.14vw);
  }

  @media (max-width: 940px) {
    padding: 0 0 0 12px;
  }
`

export const HomepageHeaderButtonPadding = styled.div`
  padding: 120px 60px;

  @media screen and (min-width: 1900px) {
    padding: calc(120px + 0.14vw) calc(60px + 0.14vw);
  }

  @media (max-width: 720px) {
    padding: 1.5rem 2rem;
  }
`
export const ContentBlockPaddingBlock = styled.div`
  padding: 0 90px;

  @media screen and (min-width: 1900px) {
    padding: 0 calc(90px + 0.14vw);
  }

  @media (max-width: 940px) {
    padding: 24px 0 30px;
  }
`

export const ContentButtonPaddingBlock = styled.div`
  padding: 0 60px;

  @media screen and (min-width: 1900px) {
    padding: 0 calc(60px + 0.14vw);
  }
`

export const BigGraphBlockPadding = styled.div`
  padding: 60px 0 0;

  @media screen and (min-width: 1900px) {
    padding: calc(60px + 0.14vw) 0 0;
  }

  @media (max-width: 720px) {
    padding: 12px 0 0;
  }
`

export const TextWithImageBlockPadding = styled.div`
  padding: 0 0 60px 0;

  @media screen and (min-width: 1900px) {
    padding: 0 0 calc(60px + 0.14vw) 0;
  }

  @media (max-width: 720px) {
    padding: 0 0 12px 0;
  }
`

export const AboutPersonBlockPadding = styled.div`
  padding: 0 0 60px 0;

  @media screen and (min-width: 1900px) {
    padding: 0 0 calc(60px + 0.14vw) 0;
  }

  @media (max-width: 720px) {
    padding: 0;
  }
`

export const BigSectionGraphTextBlockPadding = styled.div`
  padding: 70px 0 0 4vw;
  ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}

  @media (max-width: 720px) {
    padding: 50px 0;
  }
`

export const TextWithImageTextBlockPadding = styled.div`
  padding: 70px 0 0 4vw;
  ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}

  @media (max-width: 720px) {
    padding: 32px 0 0;
  }
`

export const BigSectionGraphContentBlockPadding = styled.div`
  padding: 20px 0;

  @media (max-width: 720px) {
    padding: 12px 0;
  }
`

export const HalfEnHalfPading = styled.div`
  padding: 120px 0 60px;

  @media (max-width: 720px) {
    padding: 44px 0;
  }
`

export const AboutPersonTextBlockPadding = styled.div`
  padding: 32px 0;

  @media screen and (min-width: 1900px) {
    padding: calc(32px + 0.14vw) 0;
  }

  @media (max-width: 720px) {
    padding: 16px 0 0;
  }
`

export const RVABlockPadding = styled.div`
  padding: 0 0 60px calc(20% - 8vw);
  /* width: calc(80% + 8vw); */

  @media screen and (min-width: 1900px) {
    padding: 0 0 calc(60px + 0.14vw) 0;
  }

  @media (max-width: 720px) {
    width: 100%;
    padding: 44px 0 0 0;
  }
`
