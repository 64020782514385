import styled from 'styled-components'

const Width = styled.div`
  width: 98%;
  margin: 0 auto;
  max-width: 1400px;
  position: relative;

  @media screen and (min-width: 1900px) {
    width: 80%;
    max-width: 80%;
  }

  @media (max-width: 720px) {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
`

export default Width
