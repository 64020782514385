import React from 'react'
import styled from 'styled-components'

import Width from '../elements/width'
import asset from '../../assets/bg_about.png'
import { HalfEnHalfPading } from '../elements/padding'
import { SectionTitle } from '../elements/typography'
import { innerHtml } from '../utils/useInnerHTML'

import bg from '../../assets/What_Is_RAMMfunds_Background@2x.svg'

const HalfEnHalfWrapper = styled.div`
  background: #f3f3f1;
  position: relative;
  min-height: 500px;

  .topBackground {
    background-image: url('${bg}');
    background-size: cover;
  }

  .contentAreabg {
    background-color: #fff;
    background-image: url(${asset});
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 300px auto;
    padding: 100px 80px;
    min-height: 400px;
    margin: 0 120px;
  }

  .pBlocks {
    padding: 40px 0;

    p {
      line-height: 1.8rem;

      a {
        font-weight: bold;
      }
    }

    div {
      width: 50%;
      display: inline-block;
      vertical-align: top;

      &:first-child {
        padding-right: 20px;
      }

      &:last-child {
        padding-left: 20px;
      }

      span.innerHtml {
        p {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }

  .buttonContainer {
    text-align: center;
  }

  @media (max-width: 830px) {
    .contentAreabg {
      min-height: unset;
      margin: 0 20px;
    }
  }

  @media (max-width: 720px) {
    .contentAreabg {
      margin: 0;
      padding: 28px 40px;
      background-size: 150px auto;
    }

    .pBlocks {
      padding: 20px 0 0;
      line-height: 23px;

      div {
        width: 100%;

        &:first-child {
          padding: 0;
        }
        &:last-child {
          padding: 25px 0 0;
        }
      }
    }

    .buttonContainer {
      display: none;
    }
  }
`

const HalfEnHalfModule = ({ content }) => {
    const elementref = React.useRef()

    if (content.type != 'twocolumn') {
        return <p>wrong content</p>
    }

    const data = content && content.value
    return (
        <HalfEnHalfWrapper>
            <div className="topBackground">
                <div ref={elementref} className="contentArea">
                    <Width>
                        <HalfEnHalfPading style={{ position: 'relative' }}>
                            <div className="contentAreabg">
                                <div className="titleBlock">
                                    <SectionTitle
                                        centerAlign
                                        sizeVariant="small"
                                        variant="secondary"
                                    >
                                        {content && data.section_class}
                                    </SectionTitle>
                                </div>
                                <div className="pBlocks">
                                    <div>{content && innerHtml(data.left_column_body)}</div>
                                    <div>{content && innerHtml(data.right_column_body)}</div>
                                </div>
                            </div>
                        </HalfEnHalfPading>
                    </Width>
                </div>
            </div>
        </HalfEnHalfWrapper>
    )
}
export default HalfEnHalfModule
