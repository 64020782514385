import React from 'react'

// Config
import { config } from './config'

// Component
import ProgressWrapper from './components/progressWrapper'
import StepWrapper from './views/stepWrapper'

// Steps
import NewCustomer from './components/steps/0'
import OnlineOffline from './components/steps/1'
import OnlineForm from './components/steps/2'
import WhoAreYouOffline from './components/steps/21'
import OfflinePDF from './components/steps/3'
import OfflinePDFEntity from './components/steps/12'
import PostStep1 from './components/steps/4'
import PostStep2 from './components/steps/5'
import PostOldCustomer from './components/steps/6'
import Online1 from './components/steps/7'
import Online2 from './components/steps/72'
import Online3 from './components/steps/8'
import AddAnother from './components/steps/9'

// Initial configs
const StepConfiguration = [
  {
    id: 0,
    progress: 10,
    Component: NewCustomer,
    text: config.steps.newCustomer,
  },
  {
    id: 1,
    progress: 30,
    Component: OnlineOffline,
    text: config.steps.onlineOffline,
  },
  {
    id: 2,
    progress: 50,
    Component: OnlineForm,
    text: config.steps.whatAreYou,
  },
  {
    id: 3,
    progress: 70,
    Component: OfflinePDF,
    text: config.steps.offlineSign,
  },
  {
    id: 4,
    progress: 85,
    Component: PostStep1,
    text: config.steps.nextStep1,
  },
  {
    id: 5,
    progress: 100,
    Component: PostStep2,
    text: config.steps.nextStep2,
  },
  {
    id: 6,
    progress: 100,
    Component: PostOldCustomer,
    text: config.steps.existingCustomer,
  },
  {
    id: 7,
    progress: 70,
    Component: Online1,
    text: config.steps.formPerson,
  },
  {
    id: 10,
    progress: 80,
    Component: Online2,
    text: config.steps.formPerson,
  },
  {
    id: 8,
    progress: 70,
    Component: Online3,
    text: config.steps.formEntity,
  },
  {
    id: 9,
    progress: 75,
    Component: AddAnother,
    text: config.steps.anotherPerson,
  },
  {
    id: 11,
    progress: 75,
    Component: WhoAreYouOffline,
    text: config.steps.whatAreYou,
  },
  {
    id: 12,
    progress: 70,
    Component: OfflinePDFEntity,
    text: config.steps.offlineSignEntity,
  },
]

// Container for steps
const StepContainer = ({ isActive, isOld, children }) => {
  return (
    <StepWrapper isActive={isActive} isOld={isOld}>
      {children}
    </StepWrapper>
  )
}

// App
const App = () => {
  const [active, setActive] = React.useState(0)
  const [oldSteps, setOldSteps] = React.useState([])
  const [flowType, setFlowType] = React.useState(null)
  const [type, setType] = React.useState(null)
  const [formState, setFormState] = React.useState({
    entity: '',
    person1: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    person2: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  })

  const setEntityType = (type) => {
    setFormState({
      ...formState,
      entity: type,
    })
  }

  React.useEffect(() => {
    if (active === 4 && formState.person1.firstName.length > 0) {
      var xhr = new XMLHttpRequest()
      xhr.open('POST', '/api/participation/', true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(JSON.stringify(formState))
    }
  }, [active, formState])

  const handleFormUpdate = (data, type) => {
    const newState = {
      ...formState,
      [type]: data,
    }
    setFormState(newState)
  }

  const handleNextStep = (newStep) => {
    setOldSteps([...oldSteps, active])
    setActive(newStep)
  }

  const handlePreviousStep = () => {
    if (oldSteps && oldSteps.length > 0) {
      const newStep = oldSteps[oldSteps.length - 1]
      const newSteps = [...oldSteps]
      newSteps.pop()

      // Update state
      setOldSteps(newSteps)
      setActive(newStep)
    }
  }

  const getProgress = StepConfiguration.filter((item) => item.id === active)[0]
  const progressInticator =
    getProgress.id === 4 && flowType === 'online' ? 100 : getProgress.progress
  return (
    <ProgressWrapper
      oldSteps={oldSteps}
      config={config}
      flowType={flowType}
      progress={progressInticator}
      handleClick={handlePreviousStep}
    >
      <div className="progressContentWrapper">
        {StepConfiguration.map((Step, index) => (
          <StepContainer
            isActive={Step.id === active}
            isOld={oldSteps.indexOf(Step.id) >= 0}
            key={index}
          >
            <Step.Component
              goToStep={(newStep) => {
                handleNextStep(newStep)
              }}
              setFlowType={setFlowType}
              flowType={flowType}
              handleFormUpdate={handleFormUpdate}
              text={Step.text}
              stepId={Step.id}
              setType={setType}
              setEntityType={setEntityType}
              type={type}
            />
          </StepContainer>
        ))}
      </div>
    </ProgressWrapper>
  )
}

export default App
