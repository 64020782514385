import React from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'

import PageWrapper from '../core/modules/pageWrapper'
import Width from '../core/elements/width'
import TextWithImageBlock from '../core/modules/textWithImageBlock'
import PartipationModule from '../core/modules/partipation'
import { useParticipationApi } from '../APIs/useApi'
import Loader from '../core/elements/loader'

const AboutpageWrapper = styled.div``

const Aboutpage = () => {
    const { data, isLoading, isError } = useParticipationApi('/api/v2/pages/6/')
    const containerReference = React.useRef()

    const handleScroll = () => {
        containerReference.current
            .querySelector('.scrollToMe')
            .scrollIntoView({ behavior: 'smooth' })
    }

    if (isLoading) return <Loader />
    if (isError) return null

    return (
        <AboutpageWrapper ref={containerReference}>
            <MetaTags>
                {data?.meta && (
                    <title>
                        {data.meta.seo_title ? data.meta.seo_title : 'Participeren'} - RAMM
            Funds
                    </title>
                )}
                {data?.meta?.search_description && (
                    <meta name="description" content={data.meta.search_description} />
                )}
            </MetaTags>
            <PageWrapper>
                <Width>
                    <TextWithImageBlock
                        title={data.title}
                        intro={data.intro}
                        image={data.lead_image}
                        handleScroll={handleScroll}
                    />
                    <div className="scrollToMe">
                        {' '}
                        <PartipationModule />{' '}
                    </div>
                </Width>
            </PageWrapper>
        </AboutpageWrapper>
    )
}
export default Aboutpage
