import React from 'react'
import styled from 'styled-components'
import { InformationPadding } from '../elements/padding'
import { ArticleH3, P, PaddedSectionTitle } from '../elements/typography'
import { useAboutApi } from '../../APIs/useApi'

const BlockWrapper = styled.div`
  .tables-container {
    border-spacing: 40px 0;
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
  }

  .block-row {
    display: table-row;
  }

  .blockElement {
    width: 33%;
    display: table-cell;
    vertical-align: top;
    text-align: center;
    justify-content: center;

    h3 {
      text-align: left;
    }

    p {
      line-height: 1.8rem;
    }

    border: 1px solid #097490;

    &:first-child {
      border: 1px solid #097490;
    }
    &:nth-child(2) {
      border: 1px solid #55485f;
    }
    &:nth-child(3) {
      border: 1px solid #949480;
    }
  }

  @media screen and (min-width: 1900px) {
    border-spacing: calc(40px + 0.14vw) 0;
    margin-left: calc(-40px -0.14vw);
    margin-right: calc(-40px -0.14vw);
  }

  @media (max-width: 940px) {
    .blockElement {
      width: 100%;
      display: block;
      margin-bottom: 18px;
    }
  }
`

const InfoTable = styled.div`
  padding: 20px 30px;
  .table {
    display: block;
    width: 100%;
    text-align: left;

    .table-row {
      display: block;
      width: 100%;
      border-bottom: 1px solid #09749050;
      ${(props) =>
        props.variant === 'secondary'
            ? 'border-bottom: 1px solid #55485f50;'
            : ''}
      ${(props) =>
        props.variant === 'turnary'
            ? 'border-bottom: 1px solid #94948050;'
            : ''}
      &:last-child {
        border: 0;
      }

      p {
        display: inline-block;
        width: 50%;
        vertical-align: top;
      }
      .key {
        color: #097490;
        ${(props) => (props.variant === 'secondary' ? 'color: #55485f;' : '')}
        ${(props) => (props.variant === 'turnary' ? 'color: #949480;' : '')}
      }
    }
  }
  @media (max-width: 940px) {
    padding: 12px 18px 3px;

    .table {
      .table-row {
        p {
          margin-top: 0.9em;
          margin-bottom: 0.6em;
        }
      }
    }
  }

  @media (max-width: 350px) {
    .table {
      .table-row {
        p {
          line-break: anywhere;
        }
      }
    }
  }
`

const mockData = {
    title: 'Voorwaarden',
    blocks: [
        {
            title: 'Informatie',
            info: [
                {
                    key: 'ISIN',
                    value: 'NL00150009K5',
                },
                {
                    key: 'Valuta',
                    value: 'EUR',
                },
                {
                    key: 'Min. investering',
                    value: '100.000,-',
                },
                {
                    key: 'Toe- en Uittreding',
                    value: 'Wekelijks',
                },
            ],
        },
        {
            title: 'Informatie',
            info: [
                {
                    key: 'Management Fee',
                    value: '0.083% p/m',
                },
                {
                    key: 'Performance Fee',
                    value: '10%',
                },
                {
                    key: 'High Watermark',
                    value: 'Eeuwigdurend',
                },
                {
                    key: 'Toetredingskosten',
                    value: '0.10%',
                },
            ],
        },
        {
            title: 'Informatie',
            info: [
                {
                    key: 'Bewaarder',
                    value: 'Assetcare Bewaarder Services',
                },
                {
                    key: 'Administrateur',
                    value: 'AssetCare B.V.',
                },
                {
                    key: 'Custody',
                    value: 'ING Bank & Interactive Brokers',
                },
            ],
        },
    ],
}

const InformationModule = ({ content }) => {
    const { data } = useAboutApi('/api/v2/pages/8/')
    const [stateData, setData] = React.useState(content)
    const determineVariant = {
        1: 'primary',
        2: 'secondary',
        3: 'turnary',
    }

    React.useState(() => {
        if (data?.body && data.body[4]) {
            setData(data.body[4])
        }
    }, [data])

    return (
        <BlockWrapper>
            <InformationPadding>
                {stateData?.value?.title && (
                    <PaddedSectionTitle centerAlign={true} variant="secondary">
                        {stateData.value.title}
                    </PaddedSectionTitle>
                )}
                <table className="tables-container">
                    <tr>
                        {mockData.blocks.map((item, index) => {
                            return (
                                <td
                                    key={`information-block-item-${item.title}-${index}`}
                                    className="blockElement"
                                >
                                    <InfoTable variant={determineVariant[index + 1] || 'primary'}>
                                        <ArticleH3
                                            variant={determineVariant[index + 1] || 'primary'}
                                        >
                                            {' '}
                                            {item.title}{' '}
                                        </ArticleH3>
                                        {item.info && (
                                            <div className="table">
                                                {item.info.map((information) => {
                                                    return (
                                                        <div className="table-row" key={information.key}>
                                                            <P className="key">{information.key}</P>
                                                            <P>{information.value}</P>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </InfoTable>
                                    {/* </div> */}
                                </td>
                            )
                        })}
                    </tr>
                </table>
            </InformationPadding>
        </BlockWrapper>
    )
}
export default InformationModule
