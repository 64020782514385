import React from 'react'
import styled from 'styled-components'

const TableWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 60px;
  overflow-x: auto;

  .row-wrapper {
    min-width: 70em;
    white-space: nowrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.toprow {
      font-weight: 800;

      > div {
        background: #4c717e47;
      }
    }

    > div {
      box-sizing: border-box;
      padding: 0px 10px;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      text-align: center;
      width: 7.14%;
      vertical-align: top;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      &:first-child {
        font-weight: 800;
      }

      &:last-child {
        font-weight: 800;
        border-right: 0px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
`

const TableComponent = ({ data }) => {
  const dataSet = data[0]
  const state = {}

  const add_percentages = (a, b) => {
    if (!b) return a
    if (!a) return b
    return (1 + a) * (1 + b) - 1
  }

  dataSet.data.forEach((item, index) => {
    const year = item.split('-')[0]
    const month = item.split('-')[1]

    if (!state[year]) {
      state[year] = {}
    }

    state[year][month] = add_percentages(
      state[year][month],
      dataSet.values[index]
    )
  })

  const buildScreen = () => {
    const getFixed = (number) => {
      if (!number) {
        return ''
      }
      return `${(number * 100).toFixed(2)}%`
    }

    const getTotal = (data) => {
      let total = 0
      for (const property in data) {
        total = add_percentages(total, data[property])
      }
      return total
    }

    const rows = []
    for (const property in state) {
      const Row = (
        <div className="row-wrapper">
          <div className="row-col">{property}</div>
          <div className="row-col">{getFixed(state[property]['01']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['02']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['03']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['04']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['05']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['06']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['07']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['08']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['09']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['10']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['11']) || ''}</div>
          <div className="row-col">{getFixed(state[property]['12']) || ''}</div>
          <div className="row-col">{getFixed(getTotal(state[property]))}</div>
        </div>
      )
      rows.push(Row)
    }
    return rows
  }

  return (
    <TableWrapper>
      <div className="row-wrapper toprow">
        <div className="row-col">Jaar</div>
        <div className="row-col">Jan</div>
        <div className="row-col">Feb</div>
        <div className="row-col">Mar</div>
        <div className="row-col">Apr</div>
        <div className="row-col">May</div>
        <div className="row-col">Jun</div>
        <div className="row-col">Jul</div>
        <div className="row-col">Aug</div>
        <div className="row-col">Sep</div>
        <div className="row-col">Okt</div>
        <div className="row-col">Nov</div>
        <div className="row-col">Dec</div>
        <div className="row-col">Total</div>
      </div>
      {buildScreen()}
    </TableWrapper>
  )
}
export default TableComponent
