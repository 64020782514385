import React from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'

import PageWrapper from '../core/modules/pageWrapper'
import InformationModule from '../core/modules/informationContent'
import Width from '../core/elements/width'
import ChartModule from '../core/modules/performance'
import TextWithImageBlock from '../core/modules/textWithImageBlock'
import { usePerformanceApi } from '../APIs/useApi'
import Loader from '../core/elements/loader'

const ChartPageWrapper = styled.div``

const ChartPage = () => {
    const { data, isLoading, isError } = usePerformanceApi('/api/v2/pages/5/')
    const containerReference = React.useRef()

    const handleScroll = () => {
        containerReference.current
            .querySelector('.scrollToMe')
            .scrollIntoView({ behavior: 'smooth' })
    }
    if (isLoading) return <Loader />
    if (isError) return null

    return (
        <ChartPageWrapper ref={containerReference}>
            <MetaTags>
                {data?.meta && (
                    <title>
                        {data.meta.seo_title ? data.meta.seo_title : 'Performance'} - RAMM
            Funds
                    </title>
                )}
                {data?.meta?.search_description && (
                    <meta name="description" content={data.meta.search_description} />
                )}
            </MetaTags>
            <PageWrapper>
                <Width>
                    <TextWithImageBlock
                        title={data.title}
                        intro={data.intro}
                        image={data.lead_image}
                        handleScroll={handleScroll}
                    />
                    <div className="scrollToMe">
                        <ChartModule jsonData={data.json_tables_datasets} />
                    </div>
                </Width>
                <Width>
                    <InformationModule />
                </Width>
            </PageWrapper>
        </ChartPageWrapper>
    )
}
export default ChartPage
