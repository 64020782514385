const buildCorrectLocalHref = (href = '/') => {
    if (!href) {
        href = '/'
    }
    let url = new URL(href, window.location.href)
    if (url.host === window.location.host) {
        return {
            url: url.pathname,
            internal: true,
        }
    } else {
        return {
            url: url.href,
            internal: false,
        }
    }
}
export default buildCorrectLocalHref
