import React from 'react'
import styled from 'styled-components'

const PageWrapperElement = styled.div`
  .pageContainer {
    min-height: 450px;
  }
`

const PageWrapper = ({ children }) => {
    return (
        <PageWrapperElement>
            <div className="pageContainer">{children}</div>
        </PageWrapperElement>
    )
}
export default PageWrapper
