import React from 'react'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'
import { mockItems } from './menu'

import Width from '../elements/width'
import {
    FooterPaddingBlock,
    FooterSubscribeBlockPadding,
    FooterFormPaddingBlock,
    FooterBlockPadding,
} from '../elements/padding'
import { ArticleH3 } from '../elements/typography'
import { Field } from '../elements/inputs'
import { PrimaryBigButton } from '../elements/button'

// Images
import letOpImage from '../../assets/Banner@2x.jpg'
// import rammFooterLogo from "../../assets/Logo.svg";
import { ReactComponent as RammLogo } from '../../assets/Logo.svg'
import { ReactComponent as PhoneSvg } from '../../assets/Phone.svg'
import { ReactComponent as MailSvg } from '../../assets/Mail.svg'
import { contactInfo, addressInfo } from '../utils/companyInfo'

// Styling
const FooterSubscribeBlock = styled.div`
  background: #e4e4df;

  .field1,
  .field2,
  .field3,
  .field4 {
    display: inline-block;
    vertical-align: top;
  }

  .field1,
  .field2,
  .field3 {
    padding-right: 14px;
  }

  .field1 {
    width: 20%;
  }

  .field2 {
    width: 25%;
  }

  .field3 {
    width: calc(55% - 130px);

    @media screen and (min-width: 1900px) {
      width: calc(55% - 130px - 2.28vw);
    }
  }

  .field4 {
    width: 130px;

    @media screen and (min-width: 1900px) {
      width: calc(130px + 2.28vw);
    }
  }

  @media (max-width: 940px) {
    .field1,
    .field2,
    .field3,
    .field4 {
      display: block;
      width: 100%;
      margin-bottom: 0.9rem;
    }

    h3 {
      font-size: 1.3rem;
      margin-bottom: 1.3rem;
    }
  }
`

const FooterMainBlock = styled.div`
  background: #5e5368;
  color: rgba(255, 255, 255, 0.8);

  .logoContainer {
    svg {
      width: auto;
      height: 50px;

      .st0 {
        fill: #fff;
      }

      .st1 {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }

  a {
    color: rgba(255, 255, 255, 0.8);
  }

  .footerLogo {
    width: auto;
    height: 50px;
  }

  .block {
    display: inline-block;
    vertical-align: top;
    width: 25%;

    @media (max-width: 940px) {
      display: block;
      width: 100%;

      .desktopOnly {
        display: none;
      }
    }
  }

  .footerLink {
    margin-right: 22px;
    cursor: pointer;
  }

  strong {
    color: #fff;
  }
  .contactBlock {
    padding: 13px 0 0;

    svg {
      max-height: 1rem;
      max-width: 1rem;
      margin-right: 1rem;
      path {
        fill: #fff;
      }
    }
  }
  .contactLinkBlock {
    padding: 13px 0 4px;
    .button {
        cursor: pointer;
    }
  }
  .extraPad {
    padding: 12px 0 0;
    display: block;
  }
`

const FooterImageBlock = styled.div`
  background: #fff;
  text-align: center;

  .letopImage {
    height: 56px;
    margin: 0 auto;
    text-align: center;
    display: block;

    @media (max-width: 720px) {
      width: 100%;
      height: unset;
    }
  }
`

const Footer = ({handleOpenModal}) => {
    const history = useHistory()
    const [state, setState] = React.useState({
        fname: '',
        lname: '',
        email: '',
    })

    const handleChange = (field, value) => {
        setState({
            ...state,
            [field]: value,
        })
    }

    return (
        <>
            <FooterSubscribeBlock>
                <Width>
                    <FooterPaddingBlock>
                        <FooterSubscribeBlockPadding>
                            <ArticleH3> Schrijf u in voor de nieuwsbrief </ArticleH3>
                            <div className="formContainer">
                                <form
                                    action="https://vincicapital.us1.list-manage.com/subscribe/post?u=f8d5c7a9249fc060836c5d164&amp;id=4ffb66b511"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    target="_blank"
                                    noValidate
                                >
                                    <FooterFormPaddingBlock>
                                        <div className="field1">
                                            <Field
                                                onChange={(e) => handleChange('fname', e.target.value)}
                                                type="text"
                                                value={state.fname}
                                                name="FNAME"
                                                class="required"
                                                id="mce-FNAME"
                                                placeholder="Voornaam"
                                            />
                                        </div>
                                        <div className="field2">
                                            <Field
                                                onChange={(e) => handleChange('lname', e.target.value)}
                                                value={state.lname}
                                                name="LNAME"
                                                class="required"
                                                id="mce-LNAME"
                                                placeholder="Achternaam"
                                            />
                                        </div>
                                        <div className="field3">
                                            <Field
                                                onChange={(e) => handleChange('email', e.target.value)}
                                                type="email"
                                                value={state.email}
                                                name="EMAIL"
                                                placeholder="E-mail"
                                                className="required email"
                                                id="mce-EMAIL"
                                            />
                                        </div>
                                        <div className="field4">
                                            <PrimaryBigButton
                                                buttonVariant
                                                type="submit"
                                                value="Subscribe"
                                                name="subscribe"
                                                id="mc-embedded-subscribe"
                                                handleClick={() => {
                                                    if(window.gtag && typeof window.gtag === 'function') {
                                                        window.gtag('event', 'newsletter_signup', {
                                                            'signup_source': 'website'
                                                        });
                                                    }
                                                }}
                                            >
                                                {' '}
                        Inschrijven{' '}
                                            </PrimaryBigButton>
                                        </div>
                                    </FooterFormPaddingBlock>
                                </form>
                            </div>
                        </FooterSubscribeBlockPadding>
                    </FooterPaddingBlock>
                </Width>
            </FooterSubscribeBlock>
            <FooterMainBlock>
                <Width>
                    <FooterPaddingBlock>
                        <div className="block">
                            <FooterBlockPadding>
                                <div className="logoContainer">
                                    <RammLogo />
                                </div>
                                {/* <img className="footerLogo" src={rammFooterLogo} /> */}
                            </FooterBlockPadding>
                            <div className="desktopOnly">
                                <FooterBlockPadding>
                                    <PrimaryBigButton
                                        notFullWidth
                                        variant="turnary"
                                        handleClick={() => {
                                            handleOpenModal()
                                        }}
                                        flipped
                                    >
                    Ontvang brochure
                                    </PrimaryBigButton>
                                    <div style={{ clear: 'both' }} />
                                </FooterBlockPadding>
                                <FooterBlockPadding>
                                    <a
                                        className="footerLink"
                                        onClick={() => {
                                            history.push('/privacy')
                                        }}
                                    >
                    Privacy Policy
                                    </a>
                                </FooterBlockPadding>
                                <FooterBlockPadding>
                  Copyright © 2021 Vinci Capital
                                </FooterBlockPadding>
                            </div>
                        </div>
                        <div className="block">
                            <FooterBlockPadding>
                                <strong> Adres</strong>
                                <div className="contactBlock">{addressInfo.street}</div>
                                <div className="contactBlock">
                                    {addressInfo.postalCode} {addressInfo.city}
                                </div>
                            </FooterBlockPadding>
                            <FooterBlockPadding>
                                <strong className="extraPad"> Contact</strong>
                                <div className="contactBlock">
                                    {' '}
                                    <PhoneSvg /> <a href="tel:0202101012">
                                        {contactInfo.phone}
                                    </a>{' '}
                                </div>
                                <div className="contactBlock">
                                    {' '}
                                    <MailSvg />{' '}
                                    <a href={`mailto:${contactInfo.email}`}>
                                        {contactInfo.email}
                                    </a>{' '}
                                </div>
                            </FooterBlockPadding>
                        </div>
                        <div className="block">
                            <FooterBlockPadding>
                                <strong> Menu </strong>
                                {mockItems.map((item, index) => {
                                    return (
                                        <div
                                            key={`footer-item-${index}`}
                                            className="contactLinkBlock"
                                        >
                                            <Link
                                                to={{
                                                    pathname: item.link,
                                                }}
                                            >
                                                {item.text}
                                            </Link>
                                        </div>
                                    )
                                })}
                            </FooterBlockPadding>
                        </div>
                        <div className="block">
                            <FooterBlockPadding>
                                <div className="contactLinkBlock">
                                    <Link
                                        to={{
                                            pathname: '/documenten',
                                        }}
                                    >
                    Documenten
                                    </Link>
                                </div>

                                {/* <div className="contactLinkBlock">
                                    <div className='button' onClick={() => handleOpenModal()}>
                                        Ontvang brochure
                                    </div>
                                </div> */}
                            </FooterBlockPadding>
                        </div>
                    </FooterPaddingBlock>
                </Width>
            </FooterMainBlock>
            <FooterImageBlock>
                <Width>
                    <FooterPaddingBlock>
                        <img className="letopImage" src={letOpImage} alt="let up" />
                    </FooterPaddingBlock>
                </Width>
            </FooterImageBlock>
        </>
    )
}
export default Footer
