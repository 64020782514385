import React from 'react'
import styled from 'styled-components'

// Assets
// import backButton from '../assets/backarrow.png'
import BackWrapper from '../views/backWrapper'
import { PrimarySmallButton } from '../../../elements/button'

const ViewProgressWrapper = styled.div`
  background: #fff;
  margin: 0 auto;
  padding: 40px;
  font-size: 17px;
  margin-bottom: 12vh;

  .buttonWrapper {
    padding-top: 40px;
    > div {
      &:first-child {
        margin-right: 22px;
      }
    }
  }

  .progressBarBackground {
    height: 16px;
    padding: 0;
    box-sizing: border-box;
    border-radius: 60px;
    background: rgba(0, 0, 0, 0.1);
  }

  .content {
    padding: 50px 0 0 0;

    .stepContainer {
      width: 100%;
      position: relative;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 720px) {
    /* margin: 0 auto; */
    padding: 19px;
    font-size: 17px;
    margin-bottom: 58px;

    .buttonWrapper {
      padding-top: 32px;
      > div {
        &:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      div {
        width: 100%;
      }
    }

    .progressBarBackground {
      height: 10px;
    }

    .content {
      padding: 0;

      .stepContainer {
        width: 100%;
        position: relative;
        overflow-x: hidden;
        white-space: nowrap;
      }
    }
  }
`

const ProgressBar = styled.div`
  display: inline-block;
  border-radius: 60px;
  background: #949480;
  min-width: 80px;
  height: 100%;
  transition: width 0.5s ease;
  position: relative;
  top: 0px;
  width: ${(props) => props.state}%;

  @media screen and (max-width: 720px) {
    top: -5px;
  }
`

const ProgressWrapper = ({
  progress = 10,
  children,
  config,
  oldSteps,
  handleClick,
}) => {
  return (
    <ViewProgressWrapper>
      <div className="progressBarBackground">
        <ProgressBar state={progress} />
      </div>

      <div className="content">
        <div className="stepContainer">{children}</div>
      </div>

      <BackWrapper isActive={oldSteps.length > 0}>
        <span className="backcontainer" onClick={handleClick}>
          <PrimarySmallButton notFullWidth iconLeft>
            {config.options.previous}
          </PrimarySmallButton>
        </span>
      </BackWrapper>
    </ViewProgressWrapper>
  )
}
export default ProgressWrapper
