import React from "react";
import styled from "styled-components";
import { ContactTitle } from "../../elements/typography";
import { Field } from "../../elements/inputs";
import { PrimaryBigButton } from "../../elements/button";
import { useBrochureFormApi } from "../../../APIs/useApi";
import { validateEmail, validateTel } from "../../utils/validate"


const Wrapper = styled.div`
    h3 {
        margin-bottom: 20px;   
    }
    .contactForm {
        .halfRows {
            div {
                width: 50%;
                vertical-align: top;
                display: inline-block;

                &:first-child {
                    padding-right: 12px;
                }
                &:last-child {
                    padding-left: 12px;
                }
            }
        }

        .row {
            padding: 12px 0;
        }

        .buttonContainer {
            padding-top: 4px;


            ${(props) => props.valid ? `
            ` : `
                > div {
                    background-color: #dfe8e8 !important;
                    cursor: auto !important;
                }
            `}
        }
    }
    .confirmMessage {
        padding: 20px 0;
    }
    .errorMessage {
        padding: 20px 0;
        color: red;
    }

    @media screen and (max-width: 720px) {
        .contactForm {
            .halfRows {
                div {
                    width: 100% !important;

                    &:first-child {
                        padding-right: 0;
                        padding-bottom: 24px;
                    }
                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }

`

function validateState(state) {
    let validFirstName = state?.firstname
    let validLastName = state?.lastname
    let validEmail = state?.email && validateEmail(state.email)
    let validTel = state?.telephone && validateTel(state.telephone)
    return validFirstName && validLastName && validEmail && validTel
}

const BrochureForm = () => {
    const [state, setState] = React.useState({})
    const [success, showSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)

    React.useEffect(() => {
        if(window.gtag) {
            window.gtag('config', 'AW-357291676')
        }
    }, [])

    const handleSubmit = () => {
        if (!validateState(state)) return

        if(window.gtag && typeof window.gtag === 'function') {
            window.gtag('event', 'conversion', {
                'send_to': 'AW-357291676/rfw9CNjTk_IYEJytr6oB'
            });
        }

        postRequest(state).then(() => {
            setState({})
            showSuccess(true)
            setError(false)
        }).catch((e) => {
            console.error(e)
            setError(true)
        })
    }

    const valid = validateState(state)

    const { postRequest } = useBrochureFormApi(`/api/brochure/`)

    return (
        <Wrapper valid={valid}>
            <div className="contactForm">
                <ContactTitle> Vraag de brochure aan </ContactTitle>
                {!success ? (
                    <>
                        <div className="halfRows row">
                            <div>
                                <Field
                                    placeholder="Voornaam"
                                    value={state.firstname}
                                    onChange={(e) => {
                                        setState({ ...state, firstname: e.target.value })
                                    }}
                                />
                            </div>
                            <div>
                                <Field
                                    placeholder="Achternaam"
                                    value={state.lastname}
                                    onChange={(e) => {
                                        setState({ ...state, lastname: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="fullRows row">
                            <div>
                                <Field
                                    placeholder="E-Mail"
                                    value={state.email}
                                    onChange={(e) => {
                                        setState({ ...state, email: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="fullRows row">
                            <div>
                                <Field
                                    placeholder="Telefoonnummer"
                                    value={state.telephone}
                                    onChange={(e) => {
                                        setState({ ...state, telephone: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        {error && <div className="errorMessage">
                            Er is iets misgegaan bij het indienen van het formulier.
                        </div>}
                        <div className="buttonContainer">
                            <PrimaryBigButton notFullWidth handleClick={handleSubmit}>
                                Versturen
                            </PrimaryBigButton>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="confirmMessage">
                            Bedankt voor het aanvragen van de brochure. We nemen zo
                            snel mogelijk contact met je op.
                        </div>
                    </>
                )}
            </div>
        </Wrapper>
    )
}

export default BrochureForm