import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Width from '../elements/width'
import {
    BigGraphBlockPadding,
    BigSectionGraphTextBlockPadding,
    BigSectionGraphContentBlockPadding,
} from '../elements/padding'
import { PrimaryBigButton, PrimarySmallButton } from '../elements/button'
import { sectionTitleProps, pProps } from '../elements/typography'
import backgroundSvg from '../../assets/Background.svg'
// import asset from "../../assets/Photo_Home@2x.jpg";
import { innerHtml } from '../utils/useInnerHTML'
import useApi from '../../APIs/useApi'
import { useHomeApi } from '../../APIs/useApi'
import { getImageUrl } from '../utils/getImageUrl'
import graphLink from '../../assets/graph.svg'
import processButtonText from '../utils/processButtonText'

const BigGraphBlockWrapper = styled.div`
  .backgroundContainer {
    background-image: url(${backgroundSvg});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
    background-position: 0 150px;
  }

  .imageAsset,
  .textArea {
    position: relative;
    width: 45%;
    display: inline-block;
    vertical-align: top;

    .innerHtml {
    }

    h3,
    p {
      max-width: 80%;
    }

    p {
      max-width: 500px;
      padding: 20px 0;
    }
  }

  .textArea {
    width: 55%;

    .innerHTMLContainer {
      margin: 0;
      padding: 0;
      max-width: 100%;

      span > h2,
      h3 {
        ${sectionTitleProps};
        color: #55485f;
        text-align: left;
      }

      p {
        ${pProps};
        font-family: 'SailecRegular', sans-serif;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);

        @media screen and (min-width: 1900px) {
          font-size: calc(15px + 0.14vw);
        }
      }
    }

    ul {
      li {
        padding: 12px 0;
        svg {
          display: inline-block;
          height: 34px;
          vertical-align: middle;
          margin-right: 24px;
          .st0 {
            fill: #097490;
          }
          .st1 {
            fill: #ffffff;
          }
        }

        span {
          vertical-align: middle;
          display: inline-block;
          font-weight: 600;
          color: #55485f;
        }
      }
    }
  }

  .metricsContainer {
    background: #fff;
    border-radius: 3px;
    display: inline-block;
    padding-top: 22px;

    div.metric {
      display: inline-block;
      vertical-align: top;
      font-weight: 600;
      font-size: 0.9rem;
      margin-top: 2px;
    }

    p.p_pip {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding-top: 6px;
      padding-bottom: 0px;
    }

    @media screen and (min-width: 1900px) {
      p.p_pip {
        padding-top: calc(6px + 0.24vw);
      }
    }

    span.pip {
      background: #0d9e91;
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      font-size: 0.7rem;
      padding: 3px 5px;
      border-radius: 3px;
      margin-left: 12px;

      p {
        margin: 0;
        padding: 0;
        margin-top: 3px;
      }

      @media screen and (min-width: 1900px) {
        padding: calc(3px + 0.14vw) calc(5px + 0.14vw);
        border-radius: calc(3px + 0.14vw);
        margin-left: calc(12px + 0.14vw);

        p {
          margin-top: calc(3px + 0.14vw);
        }
      }
    }
  }

  div.info {
    position: absolute;
    top: 0px;
    right: 0;
  }

  div.contactInfo {
    display: inline-block;
    vertical-align: top;
    font-size: 0.9rem;
  }

  .imageAsset {
    height: 860px;
    margin-bottom: 90px;
    position: relative;

    .imgarea {
      overflow: hidden;
      height: 860px;
    }

    .graphWrapper {
      background: #fff;
      padding: 30px;
      box-shadow: 0 12px 22px rgba(0, 0, 0, 0.1);
      position: absolute;
      bottom: -60px;
      left: -60px;
      width: 86%;
    }

    @media screen and (min-width: 1900px) {
      margin-bottom: calc(80px + 0.14vw);

      .graphWrapper {
        bottom: calc(-40px - 0.14vw);
      }
    }
  }

  .imageAsset img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 70% center;
  }

  @media screen and (min-width: 1900px) {
    .imageAsset {
      height: calc(860px + 3.14vw);

      .imgarea {
        height: calc(860px + 3.14vw);
      }
    }
  }

  @media (max-width: 1490px) {
    .imageAsset {
      .graphWrapper {
        bottom: -30px;
        left: -0px;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .imageAsset img {
      height: 90%;
    }
  }
  @media (max-width: 1000px) {
    .imageAsset img {
      object-position: 65% center;
      height: 85%;
    }
  }

  @media (max-width: 720px) {
    position: relative;

    .backgroundContainer {
      min-height: unset;
      width: 100%;
    }

    .imageAsset {
      height: 450px;

      .imgarea {
        overflow: hidden;
        height: 450px;
      }
    }

    .imageAsset {
      .graphWrapper {
        .button-holder {
          float: none;
          display: block;
        }
      }
    }

    .imageAsset,
    .textArea {
      width: 100%;
      display: block;

      h3,
      p {
        max-width: 100%;
      }

      h3 {
        font-size: 1.6rem;
      }

      p {
        padding: 0;
        margin-top: 20px;
      }
    }

    .textArea {
      width: 100%;

      ul {
        li {
          svg {
            height: 33px;
          }
        }
      }
    }

    .imageAsset img {
      /* width: 100%; */
      max-height: 270px;
      object-position: center;
      object-fit: cover;

      width: 100vw;
      position: relative;
      left: -20px;
    }
  }

  .button-holder {
    padding-top: 12px;
    float: right;
  }
`

const BigGraphModule = ({ content }) => {
    const history = useHistory()

    const moduleData = content && content.value

    const { data: metricData } = useHomeApi('/api/v2/pages/3/')
    const { data, isError, isLoading } = useApi(
        'image',
        `/api/v2/images/${moduleData.left_column_image}/`
    )

    if (isLoading) return null
    if (isError) return null

    const image = data && getImageUrl(data.meta.download_url)

    return (
        <BigGraphBlockWrapper>
            <BigGraphBlockPadding>
                <div className="backgroundContainer">
                    <Width>
                        <div className="imageAsset">
                            <div className="imgarea">
                                <img src={image} alt={data.title} />
                            </div>

                            <div className="graphWrapper">
                                <img src={graphLink} alt="performance" />
                                <div className="metrics">
                                    <div>
                                        <div className="metricsContainer">
                                            <div>
                                                <div className="metric">
                                                    <p className="p_pip">{metricData.cagr}</p>
                                                    <span className="pip">
                                                        <p>LIVE </p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button-holder">
                                            <PrimarySmallButton
                                                variant="secondary"
                                                handleClick={() => {
                                                    history.push('/performance')
                                                }}
                                            >
                                                {' '}
                        Performance chart{' '}
                                            </PrimarySmallButton>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div className="textArea">
                            <BigSectionGraphTextBlockPadding>
                                <p className="innerHTMLContainer">
                                    {moduleData && innerHtml(moduleData.right_column_body)}
                                </p>

                                <BigSectionGraphContentBlockPadding>
                                    <PrimaryBigButton
                                        notFullWidth
                                        variant="turnary"
                                        handleClick={() => {
                                            history.push('/over-ramm')
                                        }}
                                    >
                                        {moduleData && moduleData.right_cta_buttons
                                            ? processButtonText(moduleData.right_cta_buttons[0].text)
                                            : 'Hoe wij uw vermogen laten groeien'}
                                    </PrimaryBigButton>
                                </BigSectionGraphContentBlockPadding>
                            </BigSectionGraphTextBlockPadding>
                        </div>
                    </Width>
                </div>
            </BigGraphBlockPadding>
        </BigGraphBlockWrapper>
    )
}

export default BigGraphModule
