import React from 'react'
import styled from 'styled-components'

import Topbar from './topbar'
import Menu from './menu'
import Footer from './footer'
import BrochurePopup from './brochurePopup'

const RouteWrapperElement = styled.div`
    .pageContainer {
        min-height: 850px;

        p {
            p {
                line-height: 1.8rem;
            }
        }
    }
`

const RouteWrapper = ({ children, logoType }) => {
    const [modalOpen, setModalOpen] = React.useState(false)
    const handleCloseModal = () => {
        setModalOpen(false)
    }
    const handleOpenModal = () => {
        setModalOpen(true)
    }

    return (
        <RouteWrapperElement>
            <Topbar />
            <Menu logoType={logoType} handleOpenModal={handleOpenModal} />
            <div className="pageContainer">{children}</div>
            <Footer handleOpenModal={handleOpenModal} />
            {modalOpen && 
                <BrochurePopup
                    open={modalOpen}
                    handleClose={handleCloseModal}
                />
            }
        </RouteWrapperElement>
    )
}
export default RouteWrapper
