import styled from 'styled-components'

export const pProps = `
  font-size: 1rem;  
`

export const P = styled.p`
  ${pProps};
`

export const Span = styled.span`
  font-size: 1rem;
`

export const ArticleH3 = styled.h3`
  font-family: 'GilroyBold', sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 1.5rem;
  color: #097490;
  margin: 0 ${(props) => (props.centerAlign ? 'auto' : '0')};
  margin-bottom: 2vh;

  ${(props) => (props.variant === 'secondary' ? 'color: #55485f;' : '')}
  ${(props) => (props.variant === 'turnary' ? 'color: #949480;' : '')}

  @media (max-width: 940px) {
    font-size: 1.4rem;
  }
`

export const PaddedSectionTitle = styled.h3`
  font-family: 'GilroyBold', sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 2.4rem;
  color: #097490;
  margin: 0 ${(props) => (props.centerAlign ? 'auto' : '0')};
  ${(props) => (props.centerAlign ? 'text-align: center;' : '')}
  padding: 0;
  padding-bottom: 40px;

  ${(props) => (props.variant === 'secondary' ? 'color: #55485f;' : '')}
  ${(props) => (props.variant === 'turnary' ? 'color: #949480;' : '')}
  ${(props) => (props.sizeVariant === 'small' ? 'font-size: 2.5rem;' : '')}
  @media screen and (min-width: 1900px) {
    padding-bottom: calc(40px + 0.14vw) 0;
  }

  @media (max-width: 720px) {
    font-size: 1.7rem;
    padding-bottom: 22px;
  }
`

export const sectionTitleProps = `
  font-family: 'GilroyBold', sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 2.9rem;
  color: #097490;
  margin: 0 auto;
  text-align: center;
  padding: 0;

  @media (max-width: 720px) {
    font-size: 2.3rem;
  }
`

export const sectionTitleSmallProps = `
  font-family: 'GilroyBold', sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 2.5rem;
  color: #097490;
  margin: 0 auto;
  text-align: center;
  padding: 0;

  @media (max-width: 720px) {
    font-size: 1.7rem;
  }
`

export const SectionTitle = styled.h3`
  ${sectionTitleProps};
  margin: 0 ${(props) => (props.centerAlign ? 'auto' : '0')};
  ${(props) => (props.centerAlign ? 'text-align: center;' : '')}

  ${(props) => (props.variant === 'secondary' ? 'color: #55485f;' : '')}
  ${(props) => (props.variant === 'turnary' ? 'color: #949480;' : '')}
  ${(props) => (props.sizeVariant === 'small' ? 'font-size: 2.5rem;' : '')}

  @media (max-width: 720px) {
    ${(props) =>
        props.sizeVariant === 'small' ? 'font-size: 1.7rem' : 'font-size: 2.3rem'}
  }
`

export const sectionSubTitleProps = `
  font-family: 'GilroyBold', sans-serif;
  font-weight: 400;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 2.9rem;
  color: #097490;
  margin: 0 auto;
  text-align: center;
  padding: 0;

  @media (max-width: 720px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`

export const SectionSubTitle = styled.h3`
  ${sectionSubTitleProps};
  margin: 0 ${(props) => (props.centerAlign ? 'auto' : '0')};
  ${(props) => (props.centerAlign ? 'text-align: center;' : '')}
  ${(props) => (props.sizeVariant === 'small' ? 'font-size: 2.3rem' : '')}

  ${(props) => (props.variant === 'secondary' ? 'color: #55485f;' : '')}
  ${(props) => (props.variant === 'turnary' ? 'color: #949480;' : '')}

  @media (max-width: 720px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`

export const ContactInfo = styled.h3`
  font-family: 'GilroyMedium', sans-serif;
  font-weight: 400;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 1.8rem;
  line-height: 2rem;
  color: #097490;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 720px) {
    font-size: 1.6rem;
  }
`

export const itemTitleProps = `
  font-family: "GilroyBold", sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 2rem;
  color: #55485f;
  margin: 0;
  padding: 0;

  @media (max-width: 720px) {
    font-size: 1.4em;
  }
`

export const ItemTitle = styled.h3`
  ${itemTitleProps};
  /* font-family: "GilroyBold", sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 2rem;
  color: #55485f;
  margin: 0;
  padding: 0; */

  ${(props) => (props.variant === 'primary' ? 'color: #097490;' : '')}
  ${(props) =>
        props.variant === 'secondary'
            ? 'color: #55485f;'
            : ''} /* @media (max-width: 720px) {
    font-size: 1.4em;
  } */
`

export const FAQTitle = styled.h3`
  font-family: 'GilroyMedium', sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #097490;
  margin: 0;
  padding: 0;

  @media (max-width: 720px) {
    font-size: 1.2rem;
  }
`

export const ContactTitle = styled.h3`
  font-family: 'GilroyMedium', sans-serif;
  font-weight: 400;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 2rem;
  color: #097490;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 720px) {
    font-size: 1.7rem;
  }
`
