// For testing
export const buildMeMegaSetOfData = () => {
  const dataPoints = 150
  const dateData = []
  const valueData = []

  let month = 0
  let year = 10
  for (let i = 0; i < dataPoints; i++) {
    if (month === 12) {
      year++
      month = 0
    }

    month++
    dateData.push(`20${year}-${month}-01`)

    const randomIntFromInterval = (min, max) => {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const cost = randomIntFromInterval(50 + i, 86 + i)
    valueData.push(cost)
  }

  return {
    data: dateData,
    values: valueData,
  }
}

export const indexes = (dataset, start_date) => {
  // dataset must be ordered
  // H(s) = 100
  // H(n) = H(n-1) * (1+B(n))
  let dateToUse = start_date

  if (!start_date) {
    dateToUse = dataset.data[0]
  }

  var results = [],
    value = 100
  ;[].forEach.call(dataset.data, (date, index) => {
    if (Date.parse(date) > Date.parse(dateToUse)) {
      value *= 1 + dataset.values[index]
    }
    results.push(value)
  })
  return results
}

export const drawdown = (dataset, start_date) => {
  // dataset must be ordered
  // H(s) = 100
  // H(n) = H(n-1) * (1+B(n))
  // I(n) = MIN(0; (H(n)) / (MAX(H(s):H(n))) - 1)
  let dateToUse = start_date

  if (!start_date) {
    dateToUse = dataset.data[0]
  }

  var results = [],
    value = 100,
    max_value = 100,
    draw_down = 0
  ;[].forEach.call(dataset.data, (date, index) => {
    if (Date.parse(date) > Date.parse(dateToUse)) {
      value *= 1 + dataset.values[index]
      max_value = Math.max(value, max_value)
      draw_down = Math.min(0, value / max_value - 1)
    }
    results.push(`${(draw_down * 100).toFixed(2)}%`)
  })
  return results
}
