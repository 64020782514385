import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import PageWrapper from '../core/modules/pageWrapper'
import Width from '../core/elements/width'
import { SectionTitle, sectionTitleProps } from '../core/elements/typography'
import { PrimaryBigButton } from '../core/elements/button'
import image from '../assets/Performance_Header@2x.jpg'
import { BlogCard } from './blog'
import { useBlogArticleApi, useBlogArticlesApi } from '../APIs/useApi'
import { innerHtml } from '../core/utils/useInnerHTML'
import { useHistory } from 'react-router-dom'
import { getImageUrl } from '../core/utils/getImageUrl'
import Loader from '../core/elements/loader'

const BlogArticleWrapper = styled.div`
  .fullPageImageWrapper {
    width: 100%;
    overflow: hidden;
    height: 30vh;
    min-height: 500px;

    img {
      width: 100%;
    }
  }

  .content {
    padding: 0 7vw;

    div.card {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 40px;
      box-sizing: border-box;

      &:nth-child(even) {
        padding-left: 20px;
      }

      &:nth-child(odd) {
        padding-right: 20px;
      }
    }
  }

  .ArticleContent {
    padding: 60px 22vw 30px 12vw;

    p {
      line-height: 1.8rem;
    }

    h3 {
      text-align: left !important;
    }

    .innerHtml {
      h1,
      h2,
      h3 {
        ${sectionTitleProps};
        color: #949480;
      }
    }
  }

  .contentBlock {
    padding: 60px 12vw 30px 22vw;

    .innerHtml {
      .richtext-image {
        width: 100%;
        height: auto;
      }
    }

    p {
      line-height: 1.8rem;
    }
  }

  .relatedBlogs {
    padding-bottom: 100px;
    h3.title {
      text-align: center;
      padding-bottom: 60px;
      padding-top: 60px;
    }
    .buttonContainer {
      text-align: center;
    }
  }

  @media screen and (max-width: 720px) {
    .fullPageImageWrapper {
      width: 100%;
      overflow: hidden;
      height: auto;
      max-height: 100vw;
      min-height: unset;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
    p {
      line-height: 1.5rem;
    }

    .content {
      padding: 0;

      div.card {
        width: 100%;
        display: block;
        margin-bottom: 18px;

        &:nth-child(even) {
          padding-left: 0;
        }

        &:nth-child(odd) {
          padding-right: 0;
        }
      }
    }

    .ArticleContent {
      h3 {
        line-height: 2.25rem;
      }
      p {
        line-height: 1.5rem;
        margin-top: 22px;
        margin-bottom: 0;
      }
    }

    .contentBlock {
      padding: 16px 0;
    }

    .relatedBlogs {
      padding-bottom: 58px;
      h3.title {
        padding-bottom: 14px;
        padding-top: 8px;
      }
    }
  }
`

const BlogArticle = () => {
    const params = useParams()
    const history = useHistory()

    const { data, isError, isLoading } = useBlogArticleApi(
        `blogArticle-${params.id}`,
        `/api/v2/pages/${params.id}`
    )

    // get the last 3 published blogs
    const {
        data: articlesData,
        isError: articlesError,
        isLoading: articlesLoading,
    } = useBlogArticlesApi(
        `/api/v2/pages/?child_of=11&order=-first_published_at&limit=3`
    )

    if (isLoading) return <Loader />
    if (isError) return null

    // Filter out current blog if applicable
    const text = data.body.filter((item) => {
        return item.type == 'paragraph'
    })

    const blogImageLocation = data.lead_image?.meta?.download_url
    const blogImage = blogImageLocation ? getImageUrl(blogImageLocation) : image

    // Get the first two results
    const otherBlogs = articlesData.items
        .filter((item) => {
            return item.id !== parseInt(params.id)
        })
        .slice(0, 2)

    let blogData = text && text[0] && text[0].value && text[0].value.body
    let adjustedBlogData = blogData.replaceAll(
        `src="/media/`,
        `src="${getImageUrl('/media/')}`
    )

    return (
        <BlogArticleWrapper>
            <MetaTags>
                {data?.meta && (
                    <title>
                        {data.meta.seo_title ? data.meta.seo_title : data?.meta?.title} -
            RAMM Funds
                    </title>
                )}
                {data?.meta?.search_description && (
                    <meta name="description" content={data.meta.search_description} />
                )}
            </MetaTags>
            <PageWrapper>
                <Width>
                    <div className="ArticleContent">
                        {data.title && (
                            <SectionTitle variant="secondary">{data?.title}</SectionTitle>
                        )}
                        {data.intro && innerHtml(data.intro)}
                    </div>
                </Width>

                <div className="fullPageImageWrapper">
                    <img src={blogImage} className="fullpageImage" />
                </div>

                <Width>
                    <div className="contentBlock">
                        {text && text[0].value.title && innerHtml(text[0].value.title)}
                        {text && text[0].value.body && innerHtml(adjustedBlogData)}
                    </div>
                    <div className="relatedBlogs">
                        <SectionTitle
                            variant="secondary"
                            sizeVariant="small"
                            className="title"
                        >
                            {' '}
              Meer blogs.{' '}
                        </SectionTitle>
                        {!articlesError && !articlesLoading && (
                            <div className="content">
                                {otherBlogs.map((x, index) => (
                                    <div key={`blog-item-${index}`} className="card">
                                        {' '}
                                        <BlogCard data={x} />{' '}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="buttonContainer">
                            <PrimaryBigButton
                                notFullWidth
                                centerAlign
                                handleClick={() => {
                                    history.push('/blog')
                                }}
                            >
                Bekijk blogoverzicht
                            </PrimaryBigButton>
                        </div>
                    </div>
                </Width>
            </PageWrapper>
        </BlogArticleWrapper>
    )
}
export default BlogArticle
