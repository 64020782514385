// External libraries
import React, { useRef } from 'react'
import ReactGA from 'react-ga4'

import { Switch, Route, useLocation } from 'react-router-dom'
import { PrimarySmallButton } from './core/elements/button'
import Width from './core/elements/width'
import RouteWrapper from './core/modules/routeWrapper'

import Home from './pages/home'
import About from './pages/about'
import Chart from './pages/performance'
import Partipation from './pages/partipation'
import Blog from './pages/blog'
import Contact from './pages/contact'
import BlogArticle from './pages/blogArticle'
import Privacy from './pages/privacy'
import Documents from './pages/documents'
import FAQPage from './pages/faq'

// Mock Api Call
// import mockMetaApiCall from './mocks/_meta'
// import useApi from './APIs/useApi'

function getCookie(cname) {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return false
}

// Router
let timeout
const ApplicationRouter = () => {
    const pageReference = useRef()
    let location = useLocation()
    const [newLocation, setLocation] = React.useState(location)
    const [hasCookie, setHadCookie] = React.useState(
        getCookie('vinciCookieConsent')
    )
    const [gaInit, setGaInit] = React.useState(false)

    const handleAddCookie = () => {
        document.cookie = 'vinciCookieConsent=true'
        setHadCookie(true)
    }

    React.useEffect(() => {
        if (!gaInit && hasCookie) {
            setGaInit(true)
            ReactGA.initialize('G-68QZCWSZY9')
            ReactGA.send({ hitType: "pageview", page: location })
            // ReactGA.pageview(location)
        }
    }, [hasCookie])

    React.useEffect(() => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            setLocation(location)

            if (hasCookie && gaInit) {
                ReactGA.send({ hitType: "pageview", page: location })
            }
        }, 30)
    }, [location])

    React.useEffect(() => {
        if (pageReference.current && pageReference.current.scrollIntoView) {
            pageReference.current.scrollIntoView()
        }
    }, [pageReference, newLocation])

    return (
        <div ref={pageReference}>
            <RouteWrapper logoType={newLocation.pathname === '/' ? 'home' : ''}>
                <Switch location={newLocation}>
                    {/* Slash route - home */}
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/about" exact>
                        <About />
                    </Route>
                    <Route path="/over-ramm" exact>
                        <About />
                    </Route>
                    <Route path="/performance" exact>
                        <Chart />
                    </Route>
                    <Route path="/partipation" exact>
                        <Partipation />
                    </Route>
                    <Route path="/participeren" exact>
                        <Partipation />
                    </Route>
                    <Route path="/blog" exact>
                        <Blog />
                    </Route>
                    <Route path="/blog/:id" exact>
                        <BlogArticle />
                    </Route>
                    <Route path="/contact" exact>
                        <Contact />
                    </Route>
                    <Route path="/privacy" exact>
                        <Privacy />
                    </Route>
                    <Route path="/documents_list" exact>
                        <Documents />
                    </Route>
                    <Route path="/documenten" exact>
                        <Documents />
                    </Route>
                    <Route path="/faq" exact>
                        <FAQPage />
                    </Route>
                </Switch>
            </RouteWrapper>
            {!hasCookie && (
                <div className={`cookie-block`}>
                    <Width>
                        <div>
                            <span className="text">
                Deze website maakt gebruik van cookies om analyses op te bouwen,
                geeft u toestemming voor het gebruik van cookies op RAMM?
                            </span>
                        </div>
                        <div>
                            <PrimarySmallButton handleClick={handleAddCookie}>
                                {' '}
                Geen probleem{' '}
                            </PrimarySmallButton>
                        </div>
                    </Width>
                </div>
            )}
        </div>
    )
}
export default ApplicationRouter
