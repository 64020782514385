import styled from 'styled-components'

export const Field = styled.input`
  outline: 0;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;

  @media screen and (min-width: 1900px) {
    padding: calc(16px + 0.14vw);
    font-size: 0.62vw;
  }
`

export const Textarea = styled.textarea`
  outline: 0;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  min-height: 140px;
  font-family: inherit;
  font-size: inherit;

  @media screen and (min-width: 1900px) {
    padding: calc(16px + 0.14vw);
    font-size: 0.62vw;
  }
`
