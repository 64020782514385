const filterData = (data2, values2, start, end) => {
  const data = [...data2]
  const values = [...values2]
  // If we're not ready do not process
  if (!data || !values || !start || !end) {
    return {
      data,
      values,
    }
  }

  const newData = []
  const newValues = []

  const startYearToCheck = parseInt(start.split(['-'])[0])
  const endYearToCheck = parseInt(end.split(['-'])[0])

  for (let i = 0; i < data.length; i++) {
    const yearToCheck = parseInt(data[i].split('-')[0])

    // Generic Push
    const pushData = () => {
      newData.push(data[i])
      newValues.push(values[i])
    }

    const isValidDay = (start, end, lower) => {
      if (!lower && start <= end) {
        pushData()
      }

      if (lower && start >= end) {
        pushData()
      }
    }

    // Case 1 - Same year
    if (yearToCheck === startYearToCheck) {
      // Check Month
      const monthToCheck = data[i].split('-')[1]
      const monthToCheckAgainst = start.split(['-'])[1]

      if (parseInt(monthToCheck) >= parseInt(monthToCheckAgainst)) {
        if (parseInt(monthToCheck) === parseInt(monthToCheckAgainst)) {
          isValidDay(
            parseInt(data[i].split('-')[2]),
            parseInt(start.split(['-'])[2]),
            true
          )
        } else {
          pushData()
        }
      }
    } else if (yearToCheck > startYearToCheck && yearToCheck < endYearToCheck) {
      // Case 2 - it's a year greater than start, shorting than end
      pushData()
    } else if (yearToCheck === endYearToCheck) {
      // Check month
      const monthToCheck = parseInt(data[i].split('-')[1])
      const monthToCheckAgainst = parseInt(end.split(['-'])[1])

      if (parseInt(monthToCheck) <= parseInt(monthToCheckAgainst)) {
        if (parseInt(monthToCheck) === parseInt(monthToCheckAgainst)) {
          isValidDay(
            parseInt(data[i].split('-')[2]),
            parseInt(end.split(['-'])[2])
          )
        } else {
          pushData()
        }
      }
    }
  }

  return {
    data: newData,
    values: newValues,
  }
}
export default filterData
