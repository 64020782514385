import React from 'react'

function createMarkup(text) {
    return { __html: text }
}

export const innerHtml = (input) => {
    return (
        <span className="innerHtml" dangerouslySetInnerHTML={createMarkup(input)} />
    )
}
