import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import {
    XssTPadding,
    MetricsPadding,
    MetricsInnerPadding,
    XssTLPadding,
} from '../elements/padding'
import Width from '../elements/width'

import phoneSvg from '../../assets/Phone.svg'
import mailSvg from '../../assets/Mail.svg'
import { contactInfo } from '../utils/companyInfo'
import { useHomeApi } from '../../APIs/useApi'

const TopbarWrapper = styled.div`
  background: #e0e0d9;

  .metric {
    cursor: pointer;
  }

  .metricsContainer {
    background: #fff;
    border-radius: 3px;
    display: inline-block;

    div.metric {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 0.9rem;
      margin-top: 2px;

      &:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    p.p_pip {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      padding-top: 2px;
    }

    span.pip {
      background: #0d9e91;
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      font-size: 0.7rem;
      padding: 3px 5px;
      border-radius: 3px;
      margin-left: 12px;

      p {
        margin: 0;
        padding: 0;
        margin-top: 3px;
      }

      @media screen and (min-width: 1900px) {
        padding: calc(3px + 0.14vw) calc(5px + 0.14vw);
        border-radius: calc(3px + 0.14vw);
        margin-left: calc(12px + 0.14vw);

        p {
          margin-top: calc(3px + 0.14vw);
        }
      }
    }
  }

  div.info {
    position: absolute;
    top: 0px;
    right: 0;
  }

  div.contactInfo {
    display: inline-block;
    vertical-align: top;
    font-size: 0.9rem;
  }

  @media (max-width: 720px) {
    .metricsContainer {
      div.metric {
        font-size: 0.8rem;

        &:first-child {
          margin-top: 3px;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
    div.info {
      right: 20px;
    }
    div.contactInfo {
      span {
        display: none;
      }
      img.icon {
        height: 15px;
        margin-right: 0;
      }
    }
  }
`

const TopBar = () => {
    const history = useHistory()
    const { data, isLoading, isError } = useHomeApi('/api/v2/pages/3/')
    return (
        <TopbarWrapper>
            <XssTPadding>
                <Width>
                    {!isLoading && !isError && (
                        <div className="metricsContainer">
                            <MetricsPadding>
                                <MetricsInnerPadding
                                    className="metric"
                                    onClick={() => {
                                        history.push('/performance')
                                    }}
                                >
                                    {data.year_to_date}
                                </MetricsInnerPadding>
                                <MetricsInnerPadding
                                    className="metric"
                                    onClick={() => {
                                        history.push('/performance')
                                    }}
                                >
                                    <p className="p_pip">{data.cagr}</p>
                                    <span className="pip">
                                        <p>LIVE </p>
                                    </span>
                                </MetricsInnerPadding>
                            </MetricsPadding>
                        </div>
                    )}
                    <div className="info">
                        <XssTLPadding>
                            <XssTLPadding className="contactInfo">
                                <a href={`mailto:${contactInfo.email}`}>
                                    <img className="icon" src={mailSvg} alt="" />
                                </a>
                                <span>
                                    <a href={`mailto:${contactInfo.email}`}>
                                        {contactInfo.email}
                                    </a>
                                </span>
                            </XssTLPadding>
                            <XssTLPadding className="contactInfo">
                                <a href="tel:0202101012">
                                    <img className="icon" src={phoneSvg} alt="" />
                                </a>
                                <span>
                                    <a href="tel:0202101012">{contactInfo.phone}</a>
                                </span>
                            </XssTLPadding>
                        </XssTLPadding>
                    </div>
                </Width>
            </XssTPadding>
        </TopbarWrapper>
    )
}
export default TopBar
