import styled from 'styled-components'
import React from 'react'
import { innerHtml } from '../../utils/useInnerHTML'

export const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0px 0;

  .rangeslider-handle-min,
  .rangeslider-handle-max {
    fill: #5c9ebb;
    width: 7px;
    stroke-width: 0;
  }

  .graphContent {
    background: #fff;
    padding: 12px;
    margin: 52px 0;
    display: block;
    min-height: 735px;

    .js-plotly-plot {
      display: block !important;
      width: 100%;
    }

    input {
      width: 100%;
      display: block;
      padding: 12px 22px;
      box-sizing: border-box;
      border: 1px solid #fff;
      font-size: 0.8rem;
      color: rgb(0 0 0 / 60%);
      outline: 0;
    }
  }

  div {
    box-sizing: border-box;
  }

  .smallPad {
    padding: 8px;
    position: relative;
  }

  .calIcon {
    position: absolute;
    top: 50%;
    right: 4%;
    margin-top: -6px;
    height: 12px;
    width: auto;
  }

  > div.js-plotly-plot {
    width: 100%;
    @media screen and (max-width: 767px) {
      margin: 0 -20px;
      width: calc(100% + 40px);
    }
    height: 600px;
    max-height: 90vh;
  }
`

export const ButtonGroup = styled.div`
  > div {
    display: inline-block;
    vertical-align: top;
  }
`

export const Button = styled.div`
  margin: 0 2px;
  padding: 8px 22px;
  cursor: pointer;
  position: relative;
  top: 80px;
  z-index: 999;
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    color: rgba(0, 0, 0, 0.9);
    border-bottom: 4px solid #5c9ebb;
  }
  ${(props) =>
    props.selected
      ? 'color: rgba(0,0,0,0.9); font-weight: 700; border-bottom: 4px solid #5c9ebb;'
      : ''}
`

export const DataSelectorWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  top: 30px;
  z-index: 999;

  > div {
    padding: 18px;
    display: inline-block;
    vertical-align: top;
    width: 25%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    span {
      font-weight: 700;
      display: inline-block;
      white-space: nowrap;
      width: calc(100% - 80px);
      overflow: hidden;
    }

    select {
      border: 0px solid #fff;
      font-weight: 700;
      outline: 0;
      width: 100%;
      display: block;
      font-size: 0.8rem;
    }

    span.selectorEle {
      height: 20px;
      width: 50px;
      margin: 0px 12px 0 0;
      position: relative;
      background: #1289a7;
      display: inline-block;
      vertical-align: middle;

      &.teel {
        background: #ea2027;
      }
    }

    span.textWrappers {
      display: inline-block;
      vertical-align: middle;
      font-size: 0.7rem;
      line-height: 1rem;
      font-weight: 400;

      select {
        font-family: 'SailecRegular', sans-serif;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        display: inline-block;
        vertical-align: middle;
        font-size: 0.7rem;
        line-height: 1rem;
        font-weight: 400;
      }
    }
  }
`

export const TabledData = styled.div``

const Title = styled.h3`
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 2rem;
  font-family: 'GilroyBold', sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  word-spacing: -1px;
  font-size: 2.9rem;
  color: #097490;
  margin: 0 auto;
  text-align: center;
  padding: 0;
  margin: 0 0;
  color: #55485f;
  font-size: 2.5rem;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
`
const Desc = styled.p`
  padding: 26px 0 0 0;
  margin: 0;
  text-align: center;
  line-height: 1.5rem;

  @media screen and (max-width: 767px) {
    padding: 8px 0;
  }
`

const TitleBlockWrapper = styled.div`
  padding-top: 36px;
  @media screen and (max-width: 767px) {
    padding-top: 24px;
  }
`

export const TitleBlock = ({ title, desc }) => {
  return (
    <TitleBlockWrapper>
      {title && <Title> {title} </Title>}
      {desc && <Desc> {innerHtml(desc)} </Desc>}
    </TitleBlockWrapper>
  )
}

export const TabButtons = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  > div {
    display: inline-block;
    padding: 16px 26px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 700;
    cursor: pointer;

    &:hover,
    &.active {
      color: #097490;
      border-bottom: 4px solid #097490;
    }
  }

  @media screen and (max-width: 767px) {
    > div {
      padding: 6px 18px;
    }
  }
`
