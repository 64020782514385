import React from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'
import Loader from '../core/elements/loader'

import PageWrapper from '../core/modules/pageWrapper'
import InformationModule from '../core/modules/informationContent'
import Width from '../core/elements/width'
import HalfEnHalfModule from '../core/modules/halfEnHalfBlockBgAngled'
import TextWithImageBlock from '../core/modules/textWithImageBlock'
import AboutPersons from '../core/modules/aboutPersons'
import DownloadsModule from '../core/modules/downloadBlock'
import FAQModule from '../core/modules/faqBlock'
import { useAboutApi } from '../APIs/useApi'
// import RVABlock from '../core/modules/rvaBlock'

const AboutpageWrapper = styled.div``

const Aboutpage = () => {
    const { data, isLoading, isError } = useAboutApi('/api/v2/pages/8/')
    const containerReference = React.useRef()

    const handleScroll = () => {
        containerReference.current
            .querySelector('.scrollToMe')
            .scrollIntoView({ behavior: 'smooth' })
    }

    if (isLoading) return <Loader />
    if (isError) return null

    return (
        <AboutpageWrapper ref={containerReference}>
            <MetaTags>
                {data?.meta && (
                    <title>
                        {data.meta.seo_title ? data.meta.seo_title : 'Over RAMM'} - RAMM
            Funds
                    </title>
                )}
                {data?.meta?.search_description && (
                    <meta name="description" content={data.meta.search_description} />
                )}
            </MetaTags>
            <PageWrapper>
                <Width>
                    <TextWithImageBlock
                        title={data.title}
                        intro={data.intro}
                        image={data.lead_image}
                        handleScroll={handleScroll}
                    />
                    <AboutPersons content={data.body[0]} RVA={data.body[1]} />
                    {/* <RVABlock  /> */}
                </Width>
                <div className="scrollToMe">
                    <HalfEnHalfModule content={data.body[2]} />
                </div>
                <DownloadsModule content={data.body[3]} />

                <Width>
                    <FAQModule content={data.body[4]} />
                    <InformationModule content={data.body[5]} />
                </Width>
            </PageWrapper>
        </AboutpageWrapper>
    )
}
export default Aboutpage
