import React from "react";
import PopupModal from "../../elements/modal";
import BrochureForm from "./brochureForm";


const BrochurePopup = ({
    show,
    handleClose
}) => {

    return (
        <PopupModal show={show} handleClose={handleClose}>
            <BrochureForm handleClose={handleClose}/>
        </PopupModal>
    )
}

export default BrochurePopup
