import React from 'react'
import styled from 'styled-components'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const PaginationWrapper = styled.div`
  button {
    border-radius: 3px !important;
    padding: 0px !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 22px !important;
    text-align: center !important;
    -webkit-font-smoothing: antialiased;
    border: 1px solid #097490;
    color: #097490 !important;
    font-weight: 600;
    display: block !important;
    line-height: 41px !important;
  }

  button:hover,
  [aria-current='true'] {
    background: #097490 !important;
    color: #fff !important;
  }

  ul {
    margin: 0 auto;
    justify-content: center;
  }

  @media screen and (max-width: 720px) {
    display: none !important;
  }
`

const MobilePaginationWrapper = styled.div`
  button {
    border-radius: 3px !important;
    padding: 0px !important;
    width: 50px !important;
    height: 50px !important;
    /* line-height: 25px !important; */
    text-align: center !important;
    -webkit-font-smoothing: antialiased;
    border: 0;
    color: #fff !important;
    background-color: #55485f !important;
    font-weight: 600;
    display: block !important;
    line-height: 0px !important;
  }

  button[aria-current='true'] {
    width: unset !important;
    height: 38px !important;
    background-color: #fff !important;
    color: #55485f !important;
    padding: 0 15px !important;
    line-height: 38px !important;
  }

  /* button:hover,
  [aria-current="true"] {
    background: #097490 !important;
    color: #fff !important;
  } */

  ul {
    margin: 0 auto;
    justify-content: space-between;

    > li:empty {
      display: none;
    }
  }

  @media screen and (min-width: 721px) {
    display: none !important;
  }
`

const PaginationRounded = ({ count, handleChange }) => {
    return (
        <>
            <PaginationWrapper>
                <Pagination
                    onChange={handleChange}
                    count={count}
                    variant="outlined"
                    shape="rounded"
                />
            </PaginationWrapper>
            <MobilePaginationWrapper>
                <Pagination
                    onChange={handleChange}
                    count={count}
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                        <>
                            {(item.type == 'previous' || item.type == 'next') && (
                                <PaginationItem
                                    components={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                />
                            )}
                            {item.selected && (
                                <PaginationItem
                                    components={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                    page={`Pagina ${item.page} van ${count}`}
                                    disabled={item.selected}
                                />
                            )}
                        </>
                    )}
                    siblingCount={0}
                    boundaryCount={0}
                />
            </MobilePaginationWrapper>
        </>
    )
}
PaginationRounded.defaultProps = {
    count: 28,
    handleChange: () => {},
}
export default PaginationRounded
