import React from 'react'

// State and fetch helpers
import { useFetchApiState } from './apiStateContext'
import { postApi, fetchApi, putApi, deleteApi } from './index'

// Generic way to determine time
const buildDateTime = () => new Date().getTime() / 1000

// Generic reusable state hook
//
// Taps into the application state,
// Fetches and caches the request data
// Provides feedback on request state
//
const useFetchState = (passedKey, passedEndpoint) => {
  // Key should not change if the prop passed changes
  const [setKey] = React.useState(passedKey)

  // Connect the reusable hook to the global state
  //
  // getStateForEndpoint -> is a function for getting the global state for this endpoint
  // setStateForEndpoint -> is a function for setting global state for the endpoint.
  const {
    getStateForEndpoint,
    setStateForEndpoint,
    CSRFTokenHeader,
    csrfToken,
  } = useFetchApiState()

  const {
    data,
    isError,
    isLoading,
    error,
    cache,
    unAuthenticated,
    cacheBusted,
    found,
  } = getStateForEndpoint(setKey)
  const count = data.count
  const hasPaginatedData = data.count > 1
  let isProcessing = false

  // Forced user state update.
  const setState = (payload) => {
    setStateForEndpoint(setKey, payload)
  }

  // Handle errors in a generic way.
  const handleError = (error) => {
    console.error(error)
    setStateForEndpoint(setKey, {
      error,
      isError: true,
      isLoading: false,
      data: {},
      cacheBusted: false,
      unAuthenticated: error === 'unAuthenticated',
    })
  }

  // Handling fetch in a generic way
  //
  // case 1: General use cases doesn't use pagination
  // case 2: Paginated URL can be passed
  const handleFetch = async (url) => {
    // Contact form and mortgage doesn't have any data to return.
    if (passedKey === 'contact' || passedKey === 'mortgage') {
      setStateForEndpoint(setKey, {
        data: {},
        isLoading: false,
        isError: false,
        error: {},
        cacheBusted: false,
        cache: buildDateTime(),
      })
      return
    }

    // Fetch API layer
    fetchApi(url, {
      // [CSRFTokenHeader]: csrfToken
    })
      .then((payload) => {
        setStateForEndpoint(setKey, {
          data: payload,
          isLoading: false,
          isError: false,
          error: {},
          cacheBusted: false,
          cache: buildDateTime(),
        })
      })
      .catch((data) => {
        handleError(data)
      })
  }

  // Handle fetching the latest data from this endpoint.
  const fetchRequest = async () => {
    return handleFetch(passedEndpoint)
  }

  // Pass a method to map the post endpoint to the data.
  const postRequest = async (requestPayload) => {
    setState({ isLoading: true })

    return postApi(passedEndpoint, requestPayload, {
      [CSRFTokenHeader]: csrfToken,
    })
      .then((payload) => {
        fetchRequest()

        return payload
      })
      .catch((data) => {
        handleError(data)
      })
  }

  // generic way to handle the put, post and delete
  const putDeleteRequest = async (func, id, requestPayload) => {
    setState({ isLoading: true })

    return func(passedEndpoint, id, requestPayload, {
      [CSRFTokenHeader]: csrfToken,
    })
      .then((payload) => {
        fetchRequest()
        return payload
      })
      .catch((data) => {
        handleError(data)
      })
  }

  // API for put and delete
  const putRequest = async (id, payload) => {
    return putDeleteRequest(putApi, id, payload)
  }

  const deleteRequest = async (id, payload) => {
    return putDeleteRequest(deleteApi, id, payload)
  }

  // Mounting hook
  // Init the global state for this endpoint.
  React.useEffect(() => {
    if (!found) {
      setStateForEndpoint(
        setKey,
        {
          data: {},
          isLoading: true,
          isError: false,
        },
        true
      )
    }

    if (passedEndpoint) {
      // If cache is there, check if 15 minutes have passed before rerequesting
      // If cache is not there, do the initial get request.
      if (cache) {
        const timeNow = buildDateTime()
        const hasBeen15Minutes = timeNow >= cache + 900

        if (hasBeen15Minutes) {
          fetchRequest()
        }
      } else {
        fetchRequest()
      }
    }
  }, [])

  // Check for cacheBusting
  React.useEffect(() => {
    if (cacheBusted && !isProcessing) {
      isProcessing = true

      handleFetch(passedEndpoint)
    }
  }, [cacheBusted])

  // Return the state for this endpoint.
  return {
    data, // Full response from the backend
    count,
    hasPaginatedData,
    setState, // Allows the user to overwrite state
    isError, // Is error bollean
    isLoading, // Is loading bollean
    error, // Error message from response
    postRequest, // FUNC -> Post request
    putRequest, // FUNC -> put request
    fetchRequest, // FUNC -> fetch request
    deleteRequest, // FUNC -> delete request
    unAuthenticated, // Boollean, if the user tried to make an unauthenticated request.
  }
}
export default useFetchState
