import styled from 'styled-components'

const FormWrapper = styled.div`
  padding: 40px 0;

  .field {
    label {
      display: none;
    }

    max-width: 600px;
    padding: 10px 0;
    margin: 0 auto;
  }
`
export default FormWrapper
