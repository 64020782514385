import React from 'react'
import { StepStyling, H3, Button } from '../../views/step.views'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const Step = ({ goToStep, text, setType, setEntityType }) => {
  return (
    <StepStyling>
      <div className="contentArea">
        <H3> {text.title} </H3>
        <div className="buttonWrapper">
          <Button
            handleClick={() => {
              goToStep(7)
              setType('person')
              setEntityType(text.option1)
            }}
            notFullWidth
          >
            {' '}
            {text.option1}{' '}
          </Button>
          <Button
            handleClick={() => {
              goToStep(8)
              setType('entity')
              setEntityType(text.option2)
            }}
            notFullWidth
          >
            {' '}
            {text.option2}{' '}
          </Button>
        </div>

        {text.tip && text.tip.length > 0 && (
          <div className="textArea tiparea">
            <FontAwesomeIcon icon={faInfoCircle} />
            {text.tip}
          </div>
        )}
      </div>
    </StepStyling>
  )
}
export default Step
