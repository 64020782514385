import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import { SectionTitle, FAQTitle, P } from '../elements/typography'
import { ReactComponent as Chevron } from '../../assets/Chev_Right.svg'
import { PrimaryBigButton } from '../elements/button'
import { useFAQApi } from '../../APIs/useApi'
import { innerHtml } from '../utils/useInnerHTML'

const FAQWrapper = styled.div`
  h3 {
    margin-bottom: 0px;
    color: #5e5368;
  }

  .buttonHolder {
    text-align: center;
    padding: 42px 0 10px;
  }

  > h3 {
    margin-bottom: 2rem;
    text-align: center;
  }

  .FAQCard {
    padding: 12px 0;
    width: 75%;
    margin: 0 auto;

    .question-container {
      margin: 0;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.65);
      padding: 24px;
      background-color: rgba(148, 148, 128, 0.2);
      border-radius: 3px;
      position: relative;

      h3 {
        font-size: 1.2rem;
      }
    }
    &.open {
      .icon-container {
        svg {
          transform: rotate(270deg);
        }
      }
    }

    @media screen and (min-width: 1900px) {
      padding: calc(12px + 0.14vw);

      .question-container {
        padding: calc(24px + 0.14vw);
        border-radius: calc(3px + 0.14vw);
      }
    }
  }

  .icon-container {
    width: 40px;
    height: 40px;
    margin: 0;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: 20px;
    top: calc(50% - 20px);

    .icon {
      margin: 0;
      padding: 10px;
      width: 100%;
      height: 100%;
      position: relative;
    }

    svg {
      transition: all 0.5s ease;
      transform: rotate(90deg);
      height: 100%;
      width: 100%;
      polygon {
        fill: rgba(148, 148, 128, 0.6);
      }
    }

    @media screen and (min-width: 1900px) {
      width: calc(40px + 0.14vw);
      height: calc(40px + 0.14vw);
      right: calc(20px + 0.14vw);
      top: calc(50% - 20px - 0.07vw);
    }
  }

  .faqDescription {
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: all 0.5s ease;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
    padding-left: 20px;

    .innerHtml {
      p {
        padding: 0;
        margin: 0;
      }
    }

    p {
      line-height: 1.8rem;
    }

    &.open {
      overflow: auto;
      height: auto;
      padding: 20px;
    }

    @media screen and (min-width: 1900px) {
      border-radius: 0 0 calc(3px + 0.14vw) calc(3px + 0.14vw);
      padding-left: calc(20px + 0.14vw);

      &.open {
        padding: calc(20px + 0.14vw);
      }
    }
  }

  @media (max-width: 720px) {
    padding-bottom: 18px;
    > h3 {
      margin-bottom: 0.7rem;
    }

    .FAQCard {
      padding: 12px 0;
      width: 100%;
      margin: 0 auto;

      .question-container {
        margin: 0;
        color: rgba(0, 0, 0, 0.65);
        padding: 14px;
        background-color: rgba(148, 148, 128, 0.2);
        border-radius: 3px;
        position: relative;

        h3 {
          max-width: 80%;
        }
      }
      &.open {
        .icon-container {
          svg {
            transform: rotate(270deg);
          }
        }
      }
    }

    .icon-container {
      width: 30px;
      height: 30px;
      right: 10px;
      top: calc(50% - 15px);

      .icon {
        margin: 0;
        margin-top: -2px;
        padding: 10px;
      }
    }

    .faqDescription {
      width: 100%;
      height: 0px;
      border-radius: 0 0 3px 3px;
      padding-left: 20px;
      padding-right: 20px;

      p {
        max-width: 100%;
        margin: 0;
        line-height: 1.5rem;
      }

      &.open {
        overflow: auto;
        height: auto;
        padding: 20px 20px 12px 20px;
      }
    }
  }
`

const FAQCard = ({ question, index }) => {
    const checkfaq = new URLSearchParams(window.location.search)
    const isOpen = checkfaq.has('faq')
        ? parseInt(checkfaq.get('faq')) === index
        : false
    const [open, setOpen] = React.useState(isOpen)

    return (
        <div className={`FAQCard ${open ? 'open' : ''}`}>
            <div className="question-container" onClick={() => setOpen(!open)}>
                <FAQTitle>{question?.value?.title}</FAQTitle>
                <div className="icon-container">
                    <div className="icon">
                        <Chevron />
                    </div>
                </div>
            </div>
            <div className={`faqDescription ${open ? 'open' : ''}`}>
                <P>{innerHtml(question.value.body)}</P>
            </div>
        </div>
    )
}

const FAQModule = ({ isFAQPage = false }) => {
    const history = useHistory()
    const { data, isLoading, isError } = useFAQApi('/api/v2/pages/15/')
    let questions =
    data?.body &&
    data.body.filter((item) => {
        return item.type == 'paragraph'
    })
    if (!isFAQPage && questions) {
    // Only show first 5 questions if not on FAQ page
        questions = questions.slice(0, 5)
    }
    if (isLoading) return <p>Loading...</p>
    if (isError) return <p>Error</p>
    return (
        <>
            {isFAQPage && (
                <MetaTags>
                    {data.meta.seo_title && (
                        <title>
                            {data.meta.seo_title ? data.meta.seo_title : 'FAQ'} - RAMM Funds
                        </title>
                    )}
                    {data.meta.search_description && (
                        <meta name="description" content={data.meta.search_description} />
                    )}
                </MetaTags>
            )}
            <FAQWrapper>
                <SectionTitle sizeVariant="small"> {data.title || 'FAQ'} </SectionTitle>
                {questions.map((item, index) => (
                    <FAQCard
                        key={`faq-item-${Date.now()}-${index}`}
                        question={item}
                        startOpen={isFAQPage}
                        index={index}
                    />
                ))}
                {!isFAQPage && (
                    <div className="buttonHolder">
                        <PrimaryBigButton
                            centerAlign
                            notFullWidth
                            handleClick={() => {
                                history.push('/faq')
                            }}
                        >
              Meer veelgestelde vragen
                        </PrimaryBigButton>
                    </div>
                )}
            </FAQWrapper>
        </>
    )
}

export default FAQModule
