import styled from 'styled-components'

import { PrimaryBigButton } from '../../../elements/button'
import { ItemTitle } from '../../../elements/typography'

export const StepStyling = styled.div`
  .contentArea {
    margin: 0 auto;
    text-align: center;
    padding: 80px 0;
  }

  .descInfo {
    padding: 20px 20% 60px;
  }

  .tiparea {
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.6);
    text-align: center !important;
    padding: 0 20%;
    line-height: 1.8rem;

    svg {
      margin-right: 10px;
    }
  }

  .textArea {
    padding-top: 32px;
    margin-top: 32px;
    text-align: left;

    i {
      margin-right: 10px;
    }
  }

  .block {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 32px 22px;
    position: relative;
    display: block;
    white-space: normal;
    text-decoration: none;
    text-align: left;
    width: 100%;
    background: #d1e1e4;
    color: #097490;
    max-width: 560px;
    display: block;
    margin: 44px auto;

    h3 {
      font-size: 23px;
      margin: 0;
    }

    p {
      color: rgb(93, 93, 93);
      font-size: 0.95em;
      margin-top: 8px;
      margin-bottom: 0;
    }

    .cta {
      position: absolute;
      top: 22px;
      right: 22px;
      font-size: 28px;
    }

    &:hover {
      background: #e5eff1;
    }
  }

  @media screen and (max-width: 720px) {
    .contentArea {
      padding: 32px 0 14px;
    }

    .descInfo {
      padding: 20px 20% 60px;
    }

    .tiparea {
      font-size: 0.9em;
      color: rgba(0, 0, 0, 0.6);
      text-align: left !important;
      padding: 0;

      svg {
        margin-right: 10px;
      }
    }

    .textArea {
      padding-top: 6px;
      margin-top: 32px;
      font-size: 0.8rem;
      display: flex;

      i {
        margin-right: 10px;
      }

      svg {
        height: 30px;
        width: 30px;
        float: left;
        margin-right: 20px;
      }
    }

    .block {
      h3 {
        font-size: 23px;
        margin: 0;
      }

      p {
        color: rgb(93, 93, 93);
        font-size: 0.95em;
        margin-top: 8px;
        margin-bottom: 0;
      }

      .cta {
        position: absolute;
        top: 22px;
        right: 22px;
        font-size: 28px;
      }
    }
  }
`

export const H3 = ItemTitle

export const Button = PrimaryBigButton
