import React from 'react'
import {
  StepStyling,
  H3,
  // Button
} from '../../views/step.views'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

const Step = ({ text }) => {
  return (
    <StepStyling>
      <div className="contentArea textLeft">
        <H3> {text.title} </H3>
        <div className="descInfo">{text.desc}</div>

        <a
          rel="noreferrer"
          target="_blank"
          href={text.doc1Link}
          className="block"
        >
          <h3>{text.doc1Title} </h3>
          <p>{text.doc1}</p>
          <div className="cta"></div>
        </a>
      </div>
    </StepStyling>
  )
}
export default Step
