import React from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'
import { useHistory } from 'react-router-dom'
import Loader from '../core/elements/loader'

import {
    sectionTitleProps,
    sectionSubTitleProps,
} from '../core/elements/typography'
import { HomepageHeaderButtonPadding } from '../core/elements/padding'
import PageWrapper from '../core/modules/pageWrapper'
import { PrimaryBigButton } from '../core/elements/button'
import ContentBlock from '../core/modules/blockContent'
import InformationModule from '../core/modules/informationContent'
import BigSectionGraphBlock from '../core/modules/bigSectionGraphBlock'
import Width from '../core/elements/width'
import headerImage from '../assets/Home_Header@2x.jpg'
import logoHolder from '../assets/Home_Header_Cut-Out.svg'
import HalfEnHalfModule from '../core/modules/halfEnHalfBlock'
import { useHomeApi } from '../APIs/useApi'
import { innerHtml } from '../core/utils/useInnerHTML'

const HomepageWrapper = styled.div`
  .homepageHeader {
    width: 100%;
    height: 600px;
    overflow: hidden;
    position: relative;

    h1 {
      ${sectionTitleProps};
      color: #55485f;
    }

    h3 {
      ${sectionSubTitleProps};
      font-family: 'GilroyMedium', sans-serif;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.75);
    }

    img {
      min-width: 100%;
      max-width: 110%;
      min-height: 100%;
      width: auto;
    }

    .logoHolder {
      position: absolute;
      top: 0;
      left: -1px;
      width: 330px;
    }

    .centerContent {
      position: absolute;
      left: 50%;
      width: 450px;
      margin-left: -225px;
      top: 100px;
    }
  }

  @media screen and (min-width: 1900px) {
    .homepageHeader {
      height: calc(600px + 8vw);

      .logoHolder {
        width: calc(330px + 4.14vw);
        left: -4px;
      }

      .centerContent {
        width: calc(490px + 2vw);
        margin-left: ${`calc(-245px - 2vw)`};
        top: calc(100px + 0.14vw);
      }
    }
  }

  @media (max-width: 720px) {
    .homepageHeader {
      overflow: visible;
      height: unset;
      background-color: #e4e4de;
      position: relative;
      left: -20px;
      width: 100vw;

      img {
        position: relative;
        width: 100%;
        min-height: unset;
      }

      .logoHolder {
        display: none;
      }

      .centerContent {
        position: relative;
        left: 0;
        width: 100%;
        margin: 0;
        top: 0;
        padding: 1.5rem 0 1.2rem;
      }
    }
  }
`

const Homepage = () => {
    const { data, isLoading, isError } = useHomeApi('/api/v2/pages/3/')
    const history = useHistory()
    if (isLoading) return <Loader />
    if (isError) return null

    return (
        <HomepageWrapper>
            <MetaTags>
                {data?.meta && (
                    <title>
                        {data.meta.seo_title ? data.meta.seo_title : 'RAMM'} - RAMM Funds
                    </title>
                )}
                {data?.meta?.search_description && (
                    <meta name="description" content={data.meta.search_description} />
                )}
            </MetaTags>
            <PageWrapper logoType="home">
                {!isLoading && !isError && (
                    <>
                        <Width>
                            <div className="homepageHeader">
                                <img src={headerImage} alt="header" />
                                <div className="logoHolder">
                                    <img src={logoHolder} alt="logo ramm background" />
                                </div>
                                <div className="centerContent">
                                    {innerHtml(data.intro)}
                                    <HomepageHeaderButtonPadding>
                                        <PrimaryBigButton
                                            handleClick={() => {
                                                history.push('/over-ramm')
                                            }}
                                        >
                                            {data.cta_buttons
                                                ? innerHtml(data.cta_buttons[0].value.text)
                                                : 'Hoe wij uw vermogen laten groeien'}
                                        </PrimaryBigButton>
                                    </HomepageHeaderButtonPadding>
                                </div>
                            </div>
                            <ContentBlock content={data.body[0]} />
                        </Width>
                        <BigSectionGraphBlock content={data.body[1]} />
                        <HalfEnHalfModule content={data.body[2]} />

                        <Width>
                            <InformationModule />
                        </Width>
                    </>
                )}
            </PageWrapper>
        </HomepageWrapper>
    )
}
export default Homepage
