import React from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'

import PageWrapper from '../core/modules/pageWrapper'
import Width from '../core/elements/width'
import TextWithImageBlockContact from '../core/modules/textWithImageBlockContact'
import { Field, Textarea } from '../core/elements/inputs'
import { ContactTitle } from '../core/elements/typography'
import { PrimaryBigButton } from '../core/elements/button'
import { ReactComponent as PhoneSvg } from '../assets/Phone.svg'
import { ReactComponent as MailSvg } from '../assets/Mail.svg'
import { useContactApi, useContactFormApi } from '../APIs/useApi'
import { addressInfo, contactInfo } from '../core/utils/companyInfo'
import Loader from '../core/elements/loader'

const ContactpageWrapper = styled.div`
  .confirmMessage {
    padding: 30px 0;
  }
  .formBlock {
    padding: 20px 0 120px;

    .contactInfo,
    .contactForm {
      display: inline-block;
      vertical-align: top;
      width: 70%;
      box-sizing: border-box;
    }

    h3 {
      margin-bottom: 20px;
    }

    .contactForm {
      padding: 0 4vw;

      .halfRows {
        div {
          width: 50%;
          vertical-align: top;
          display: inline-block;

          &:first-child {
            padding-right: 12px;
          }
          &:last-child {
            padding-left: 12px;
          }
        }
      }

      .row {
        padding: 12px 0;
      }

      .buttonContainer {
        padding-top: 4px;
      }
    }

    .contactInfo {
      width: 30%;
      background: #e6e4e5;
      padding: 4vw;
      min-height: 440px;

      .contactInfoBlock {
        padding: 0 0 2vw;

        strong {
          color: #55485f;
        }

        div {
          padding: 6px 0;
        }

        &:last-child {
          padding: 0;
        }

        .contactLine {
          svg,
          p {
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            margin: 0;
          }

          svg {
            max-width: 1rem;
            max-height: 1rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    .formBlock {
      padding: 2px 0 10px;

      .contactInfo,
      .contactForm {
        display: block;
        width: 100%;
      }

      h3 {
        margin-bottom: 20px;
      }

      .contactForm {
        padding: 48px 0;

        .halfRows {
          div {
            width: 100%;
            display: block;

            &:first-child {
              padding-right: 0;
              padding-bottom: 13px;
            }
            &:last-child {
              padding-left: 0;
            }
          }
        }

        .row {
          padding: 0 0 13px;
        }

        .buttonContainer {
          padding-top: 1px;
        }
      }

      .contactInfo {
        width: 100%;
        padding: 36px 38px 26px;
        min-height: unset;

        .contactInfoBlock {
          padding: 0 0 1rem;

          strong {
            line-height: 30px;
          }

          div {
            padding: 6px 0;
          }

          &:last-child {
            padding: 0;
          }
        }
      }
    }
  }
`

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

function validateState(state) {
    let validFirstName = state?.firstname
    let validLastName = state?.lastname
    let validEmail = state?.email && validateEmail(state.email)
    let validText = state?.text && state.text.length > 2

    return validFirstName && validLastName && validEmail && validText
}

const Contactpage = () => {
    const { data, isLoading, isError } = useContactApi('/api/v2/pages/7/')
    const { postRequest } = useContactFormApi(`/api/contact/`)
    const [state, setState] = React.useState({})
    const [sucess, showSucess] = React.useState(false)
    const containerReference = React.useRef()

    const handleScroll = () => {
        containerReference.current
            .querySelector('.scrollToMe')
            .scrollIntoView({ behavior: 'smooth' })
    }

    const handleSubmit = () => {
        if (!validateState(state)) return
        postRequest(state).then(() => {
            setState({})
            showSucess(true)
        })
    }

    if (isLoading) return <Loader />
    if (isError) return null

    return (
        <ContactpageWrapper ref={containerReference}>
            <MetaTags>
                {data?.meta && (
                    <title>
                        {data.meta.seo_title ? data.meta.seo_title : 'Contact'} - RAMM Funds
                    </title>
                )}
                {data?.meta?.search_description && (
                    <meta name="description" content={data.meta.search_description} />
                )}
            </MetaTags>
            <PageWrapper>
                <Width>
                    <TextWithImageBlockContact
                        title={data.title}
                        intro={data.intro}
                        image={data.lead_image}
                        handleScroll={handleScroll}
                    />
                    <div className="formBlock scrollToMe">
                        <div className="contactInfo">
                            <div className="contactInfoBlock">
                                <strong> Adres </strong>
                                <div>{addressInfo.street}</div>
                                <div>
                                    {addressInfo.postalCode} {addressInfo.city}
                                </div>
                            </div>

                            <div className="contactInfoBlock">
                                <strong> Contact </strong>
                                <div className="contactLine">
                                    <PhoneSvg />{' '}
                                    <p>
                                        <a href={`tel:0202101012`}>{contactInfo.phone}</a>
                                    </p>
                                </div>
                                <div className="contactLine">
                                    <MailSvg />{' '}
                                    <p>
                                        <a href={`mailto:${contactInfo.email}`}>
                                            {contactInfo.email}
                                        </a>
                                    </p>{' '}
                                </div>
                            </div>

                            <div className="contactInfoBlock">
                                <strong> Informatie </strong>
                                <div>
                                    {' '}
                                    <span> KVK </span>
                                    {contactInfo.kvk}{' '}
                                </div>
                                <div>
                                    {' '}
                                    <span> BTW </span>
                                    {contactInfo.btw}{' '}
                                </div>
                            </div>
                        </div>
                        <div className="contactForm">
                            <ContactTitle> Laat een bericht achter </ContactTitle>

                            <div className="halfRows row">
                                <div>
                                    <Field
                                        placeholder="Voornaam"
                                        value={state.firstname}
                                        onChange={(e) => {
                                            setState({ ...state, firstname: e.target.value })
                                        }}
                                    />
                                </div>
                                <div>
                                    <Field
                                        placeholder="Achternaam"
                                        value={state.lastname}
                                        onChange={(e) => {
                                            setState({ ...state, lastname: e.target.value })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="fullRows row">
                                <div>
                                    <Field
                                        placeholder="E-Mail"
                                        value={state.email}
                                        onChange={(e) => {
                                            setState({ ...state, email: e.target.value })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="fullRows row">
                                <Textarea
                                    placeholder="Bericht"
                                    value={state.text}
                                    onChange={(e) => {
                                        setState({ ...state, text: e.target.value })
                                    }}
                                />
                            </div>
                            <div className="buttonContainer">
                                <PrimaryBigButton notFullWidth handleClick={handleSubmit}>
                  Versturen
                                </PrimaryBigButton>
                            </div>

                            {sucess && (
                                <div className="confirmMessage">
                  Bedankt dat je contact met ons hebt opgenomen, we nemen zo
                  snel mogelijk contact met je op.
                                </div>
                            )}
                        </div>
                    </div>
                </Width>
            </PageWrapper>
        </ContactpageWrapper>
    )
}
export default Contactpage
