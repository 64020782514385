import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { StepStyling, H3, Button } from '../../views/step.views'

const Step = ({ goToStep, text }) => {
  return (
    <StepStyling>
      <div className="contentArea">
        <H3> {text.title} </H3>

        <a
          rel="noreferrer"
          target="_blank"
          href={text.doc1Link}
          className="block"
        >
          <h3>{text.doc1Title} </h3>
          <p>{text.doc1}</p>
          <div className="cta"></div>
        </a>

        <a
          rel="noreferrer"
          target="_blank"
          href={text.doc2Link}
          className="block"
        >
          <h3>{text.doc2Title} </h3>
          <p>{text.doc2}</p>
          <div className="cta"></div>
        </a>

        <a
          rel="noreferrer"
          target="_blank"
          href={text.doc3Link}
          className="block"
        >
          <h3>{text.doc3Title} </h3>
          <p>{text.doc3}</p>
          <div className="cta"></div>
        </a>

        <div className="nextButton">
          <Button notFullWidth handleClick={() => goToStep(4)}>
            {' '}
            {text.button}{' '}
          </Button>
        </div>

        {text.tip && text.tip.length > 0 && (
          <div className="textArea tiparea">
            <FontAwesomeIcon icon={faInfoCircle} />
            {text.tip}
          </div>
        )}
      </div>
    </StepStyling>
  )
}
export default Step
