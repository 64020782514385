import styled from 'styled-components'

const StepWrapper = styled.div`
  position: relative;
  top: 0;
  left: 100%;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  display: none;

  ${(props) => (props.isActive ? `left: 0; display: block !important;` : ``)}
  ${(props) => (props.isOld ? `left: -100%; display: none;` : ``)}
`

export default StepWrapper
