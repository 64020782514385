import React from 'react'
import styled from 'styled-components'
import {
    TextWithImageBlockPadding,
    TextWithImageTextBlockPadding,
    BigSectionGraphContentBlockPadding,
} from '../elements/padding'
import { ScrollButton } from '../elements/button'
import { SectionTitle, sectionTitleSmallProps } from '../elements/typography'
import ScrollIcon from '../../assets/scroll_icon.png'
import { getImageUrl } from '../utils/getImageUrl'
import { innerHtml } from '../utils/useInnerHTML'
import asset from '../../assets/Photo_Home@2x.jpg'

const TextWithImageBlockWrapper = styled.div`
  .imageAsset,
  .textArea {
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: middle;

    h3,
    p {
      max-width: 80%;
      text-align: left;
    }

    p {
      max-width: 500px;
      padding: 20px 0;
      line-height: 1.8rem;

      @media screen and (min-width: 1900px) {
        max-width: calc(500px + 0.14vw);
        padding: calc(20px + 0.14vw) 0;
      }
    }

    .innerHtml {
      h1,
      h2,
      h3 {
        ${sectionTitleSmallProps};
        color: #949480;
      }
    }
  }

  .imageAsset {
    height: 40vh;
    overflow: hidden;
    min-height: 300px;
    max-height: 650px;

    @media screen and (min-width: 1900px) {
      min-height: calc(300px + 0.14vw);
      max-height: calc(650px + 0.14vw);
    }
  }

  .imageAsset img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .textArea {
    .button {
      img,
      span {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        height: 22px;
        margin-left: 9px;

        @media screen and (min-width: 1900px) {
          height: calc(22px + 0.14vw);
          margin-left: calc(9px + 0.14vw);
        }
      }
    }
  }

  @media (max-width: 720px) {
    .imageAsset,
    .textArea {
      width: 100%;
      display: block;

      > div > h3 {
        font-size: 2.3rem;
      }

      h3,
      p {
        max-width: 100%;
      }

      p {
        padding: 20px 0;
        margin-top: 0;
        line-height: 1.5rem;
      }
    }

    .imageAsset {
      left: -20px;
      width: 100vw;
    }

    .imageAsset img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .buttonSection {
      display: none;
    }
  }
`

let link = {
    href: '#',
    linkText: 'Scroll verder',
}

const TextWithImageBlockModule = ({
    title,
    intro,
    image,
    handleScroll = () => {},
}) => {
    const imageLocation = image ? getImageUrl(image.meta.download_url) : asset
    const altText = image ? image.title : ''
    if (!(title || intro || image)) return null
    return (
        <TextWithImageBlockWrapper>
            <TextWithImageBlockPadding>
                <div className="imageAsset">
                    {image && <img src={imageLocation} alt={altText} />}
                </div>
                <div className="textArea">
                    <TextWithImageTextBlockPadding noTopPadding>
                        {title && (
                            <SectionTitle sizeVariant="small" variant="secondary">
                                {title}
                            </SectionTitle>
                        )}
                        {intro && innerHtml(intro)}

                        <BigSectionGraphContentBlockPadding className="buttonSection">
                            <ScrollButton notFullWidth variant="quarternary">
                                <div className="button" onClick={handleScroll}>
                                    <span>{link.linkText}</span>
                                    <img src={ScrollIcon} alt="" />
                                </div>
                            </ScrollButton>
                        </BigSectionGraphContentBlockPadding>
                    </TextWithImageTextBlockPadding>
                </div>
            </TextWithImageBlockPadding>
        </TextWithImageBlockWrapper>
    )
}

export default TextWithImageBlockModule
