import React from 'react'
import Plot from 'react-plotly.js'
import { Link } from 'react-router-dom'

// Date picker
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// Core
import { config } from './config'
import { indexes, drawdown } from './utils/utils'
import Table from './table'
import CalIcon from '../../../assets/Calendar_Icon.svg'
import Loader from '../../elements/loader'

import {
  Wrapper,
  ButtonGroup,
  Button,
  DataSelectorWrapper,
  TitleBlock,
  TabButtons,
} from './views'

// import filterData from './utils/useSearch'
import filterDataByRange from './utils/filterDataByRange'

function stateReducer(state, action) {
  switch (action.type) {
    case 'reset': {
      return []
    }
    case 'set': {
      const newState = [...state]
      newState.push(action.payload[0])

      // Handle Select states
      if (newState && newState.length > 0) {
        newState.forEach((newitem, index) => {
          const item = newitem
          if (index === 0) {
            item.alwaysSelected = true
            item.selected = true
          }

          if (index === 1) {
            item.selected = true
          }

          item.name = item.name.split(',')[0]

          // Handling
          // Filter dates
          const newData = item.data.filter((item, index) => {
            if (index < 400) {
              return true
            }

            return item !== null
          })

          item.data = newData

          const newValues = item.values.filter((item, index) => {
            if (index < 400) {
              return true
            }

            return item !== null
          })

          item.values = newValues
        })
      }

      return newState
    }
    default: {
      return state
    }
  }
}

const colorIndex = [
  '#1289A7',
  '#EA2027',
  '#EA2027',
  '#EA2027',
  '#EA2027',
  '#EA2027',
  '#EA2027',
]
const dateOptions = [
  {
    label: config.buttonLabels[0],
    term: [1, 'month', 1],
  },
  {
    label: config.buttonLabels[1],
    term: [3, 'month', 2],
  },
  {
    label: config.buttonLabels[2],
    term: [6, 'month', 3],
  },
  {
    label: config.buttonLabels[3],
    term: [1, 'year', 4],
  },
  {
    label: config.buttonLabels[4],
    term: [3, 'year', 5],
  },
  {
    label: config.buttonLabels[5],
    term: [5, 'year', 6],
  },
]

const useChartData = (initalData) => {
  const url = process.env.REACT_APP_URL || `https://${window.location.host}`
  const initialApiKey = initalData && initalData[0] && initalData[0].url

  const [datesData, setDatesData] = React.useState([])
  const [datasetData, setDatasetData] = React.useState({})
  const [chartData, setChartData] = React.useReducer(stateReducer, [])

  function download(dataset) {
    return fetch(`${url}${dataset.url}`).then((response) => response.json())
  }

  React.useEffect(() => {
    if (!initialApiKey && chartData.length > 0) {
      return
    }

    fetch(`${url}${initialApiKey}`)
      .then((response) => response.json())
      .then((data) => {
        setDatesData(data.dates)
        setDatasetData(data)
      })
  }, [initialApiKey])

  React.useEffect(() => {
    if (datasetData.datasets && datasetData.datasets.length > 0) {
      let index = 0

      const dataSetsToUse = datasetData.datasets.filter((item) => {
        return (
          item &&
          item.name &&
          item.name.includes('1D% change') &&
          item.name !== null &&
          item.name !== 'Datum'
        )
      })

      const chainPromiseDownload = (data) => {
        if (data.name && data.name !== 'Datum') {
          download(data).then((response) => {
            const state = []
            const newResponse = {
              ...response,
              data: datesData,
            }
            state.push(newResponse)
            setChartData({ type: 'set', payload: state })

            // cycle
            index = index + 1
            if (index === dataSetsToUse.length) {
              return
            }
            chainPromiseDownload(dataSetsToUse[index])
          })
        } else {
          index = index + 1

          if (index !== dataSetsToUse.length) {
            chainPromiseDownload(dataSetsToUse[index])
          }
        }
      }
      chainPromiseDownload(dataSetsToUse[index])
    }
  }, [datasetData])
  return { chartData }
}

function App({ jsonData }) {
  // State
  const { chartData } = useChartData(jsonData)

  const [datasetSelected, setDataSet] = React.useState('')
  const [selectedItem, setSelected] = React.useState(null)
  const [term, setTerm] = React.useState(null)
  const [data, setData] = React.useState([])
  const [dataToRender, setDataToRender] = React.useState(null)
  const [drawDataToRender, setDrawDataToRender] = React.useState(null)

  //
  const [startDate, setStartDate] = React.useState(new Date())
  const [endDate, setEndDate] = React.useState(new Date())

  const [tab, setTab] = React.useState('chart')
  let timelineTO = null
  let processTimeline

  React.useEffect(() => {
    clearTimeout(processTimeline)

    processTimeline = setTimeout(() => {
      setData(chartData)
    }, 300)
  }, [chartData])

  React.useEffect(() => {
    let updatedData = [...data]
    if (updatedData && updatedData.length > 0) {
      updatedData.forEach((item) => {
        if (item.selected && !item.alwaysSelected) {
          item.selected = false
        }

        if (item.name === datasetSelected) {
          item.selected = true
        }
      })

      setData(updatedData)

      const defaults = updatedData[0].data

      setStartDate(new Date(defaults[0]))
      setEndDate(new Date(defaults[defaults.length - 1]))
    }
  }, [datasetSelected])

  React.useEffect(() => {
    let newdata = []
    let drawDownDataarr = []

    if (data && data.length > 0 && term !== 'custom') {
      data.forEach((item, index) => {
        if (!item.selected) {
          return null
        }

        let startDateField = item.data[0]
        if (term) {
          startDateField = new Date(item.data[item.data.length - 1])
          if (term[1] === 'month') {
            startDateField = startDateField.setMonth(
              startDateField.getMonth() - term[0]
            )
          }

          if (term[1] === 'year') {
            startDateField = startDateField.setFullYear(
              startDateField.getFullYear() - term[0]
            )
          }

          const convertedDate = new Date(startDateField)
          startDateField = convertedDate.toISOString().split('T')[0]
        }

        // Reset selector
        setStartDate(new Date(startDateField))
        setEndDate(new Date(item.data[item.data.length - 1]))

        const filteredData = filterDataByRange(
          item.data,
          item.values,
          startDateField,
          item.data[item.data.length - 1]
        )
        const indexData = indexes(filteredData)
        const drawDownData = drawdown(filteredData)

        const set = {
          type: 'scatter',
          mode: 'lines',
          name: item.name,
          titlefont: { color: '#E91E63' },
          x: filteredData.data,
          y: indexData,
          line: {
            color: colorIndex[index],
            width: index > 0 ? 1 : 2,
          },
        }
        const drawDownSet = {
          type: 'scatter',
          mode: 'lines',
          name: item.name,
          titlefont: { color: '#E91E63' },
          x: filteredData.data,
          y: drawDownData,
          line: {
            color: colorIndex[index],
            width: index > 0 ? 1 : 2,
          },
        }
        newdata.push(set)
        drawDownDataarr.push(drawDownSet)
      })
      setDataToRender(newdata)
      setDrawDataToRender(drawDownDataarr)
    }
  }, [data, term])

  if (!dataToRender) {
    return <Loader />
  }

  const processTimeSelection = (startRange, endRange) => {
    let newdata = []
    let drawDownDataarr = []
    data.forEach((item, index) => {
      if (!item.selected) {
        return null
      }

      const filteredData = filterDataByRange(
        item.data,
        item.values,
        startRange,
        endRange
      )
      const indexData = indexes(filteredData)
      const drawDownData = drawdown(filteredData)

      const set = {
        type: 'scatter',
        mode: 'lines',
        name: item.name,
        titlefont: { color: '#E91E63' },
        x: filteredData.data,
        y: indexData,
        line: {
          color: colorIndex[index],
        },
      }

      const drawDownSet = {
        type: 'scatter',
        mode: 'lines',
        name: item.name,
        titlefont: { color: '#E91E63' },
        x: filteredData.data,
        y: drawDownData,
        line: {
          color: colorIndex[index],
        },
      }
      newdata.push(set)
      drawDownDataarr.push(drawDownSet)
    })
    setDataToRender(newdata)
    setDrawDataToRender(drawDownDataarr)

    setTerm('custom')
    setSelected('custom')
  }

  const processTimeLine = (e) => {
    if ((e && e['xaxis.range[0]']) || (e && e['xaxis.range'])) {
      const startRange = e['xaxis.range']
        ? e['xaxis.range'][0].split(' ')[0]
        : e['xaxis.range[0]'].split(' ')[0]
      const endRange = e['xaxis.range']
        ? e['xaxis.range'][1].split(' ')[0]
        : e['xaxis.range[1]'].split(' ')[0]

      // Set the date ranges to match
      setStartDate(new Date(startRange))
      setEndDate(new Date(endRange))

      // Continue
      let newdata = []
      let drawDownDataarr = []
      data.forEach((item, index) => {
        if (!item.selected) {
          return null
        }

        const filteredData = filterDataByRange(
          item.data,
          item.values,
          startRange,
          endRange
        )
        const indexData = indexes(filteredData)
        const drawDownData = drawdown(filteredData)

        const set = {
          type: 'scatter',
          mode: 'lines',
          name: item.name,
          titlefont: { color: '#E91E63' },
          x: filteredData.data,
          y: indexData,
          line: {
            color: colorIndex[index],
          },
        }

        const drawDownSet = {
          type: 'scatter',
          mode: 'lines',
          name: item.name,
          titlefont: { color: '#E91E63' },
          x: filteredData.data,
          y: drawDownData,
          line: {
            color: colorIndex[index],
          },
        }
        newdata.push(set)
        drawDownDataarr.push(drawDownSet)
      })
      setDataToRender(newdata)
      setDrawDataToRender(drawDownDataarr)

      setTerm('custom')
      setSelected('custom')
    }
  }

  if (chartData.length <= 1) {
    return <Loader />
  }

  // Remder
  return (
    <Wrapper>
      <TabButtons>
        <div
          onClick={() => setTab('chart')}
          className={`tabButton ${tab === 'chart' ? 'active' : ''}`}
        >
          {config.tab.one}
        </div>
        <div
          onClick={() => setTab('table')}
          className={`tabButton ${tab === 'table' ? 'active' : ''}`}
        >
          {config.tab.two}
        </div>
      </TabButtons>
      {tab === 'table' && (
        <div>
          <TitleBlock title={config.table.title} desc={config.table.desc} />
          <Table data={data} />
        </div>
      )}
      {tab === 'chart' && (
        <>
          <div className="graphContent">
            <TitleBlock
              title={config.performance.title}
              desc={config.performance.desc}
            />
            <DataSelectorWrapper>
              <div>
                <span className="selectorEle"></span>
                <span className="textWrappers">{data[0].name}</span>
              </div>
              <div>
                <span className="teel selectorEle"></span>
                <span className="textWrappers">
                  <select
                    value={datasetSelected}
                    onChange={(e) => {
                      setDataSet(e.target.value)
                    }}
                  >
                    {data.map((item) => {
                      if (item.alwaysSelected) {
                        return null
                      }
                      return (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </span>
              </div>

              <div className="smallPad">
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    processTimeSelection(
                      new Date(date).toISOString().split('T')[0],
                      new Date(endDate).toISOString().split('T')[0]
                    )
                  }}
                />
                <img src={CalIcon} alt="calendar" className="calIcon" />
              </div>
              <div className="smallPad">
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date)
                    processTimeSelection(
                      new Date(startDate).toISOString().split('T')[0],
                      new Date(date).toISOString().split('T')[0]
                    )
                  }}
                />
                <img src={CalIcon} alt="calendar" className="calIcon" />
              </div>
            </DataSelectorWrapper>

            <ButtonGroup>
              <Button
                selected={selectedItem === null}
                onClick={() => {
                  setTerm(null)
                  setSelected(null)
                }}
              >
                {config.allTime}
              </Button>
              {dateOptions.map((item) => (
                <Button
                  selected={item.term[2] === selectedItem}
                  onClick={() => {
                    setTerm(item.term)
                    setSelected(item.term[2])
                  }}
                  key={item.term}
                >
                  {item.label}
                </Button>
              ))}

              {selectedItem === 'custom' && (
                <Button selected={selectedItem === 'custom'} onClick={() => {}}>
                  {config.custom}
                </Button>
              )}
            </ButtonGroup>
            <Plot
              data={dataToRender}
              onRelayout={(e) => {
                clearTimeout(timelineTO)
                timelineTO = setTimeout(() => {
                  processTimeLine(e)
                }, 500)
              }}
              config={{
                locale: 'nl',
                displayModeBar: false,
                displaylogo: false,
                responsive: true,
                modeBarButtonsToRemove: [
                  'select2d',
                  'lasso2d',
                  'sendDataToCloud',
                  'toggleSpikelines',
                  'resetViewMapbox',
                  'hoverClosestCartesian',
                  'hoverCompareCartesian',
                ],
              }}
              layout={{
                xaxis: {
                  type: 'date',
                  rangeslider: { thickness: 0.2 },
                },
                yaxis: {
                  autorange: true,
                  range: [0, 298],
                  type: 'linear',
                  title: {
                    text: 'Performance (Indexed)',
                    standoff: 40,
                  },
                },
                font: {
                  family: `'Lato', sans-serif`,
                  color: 'rgba(0,0,0,0.4)',
                  size: '13',
                },
                margin: {
                  pad: '12',
                },
                showlegend: false,
              }}
              useResizeHandler
            />

            <div className="graphFaq">
              <Link to="/faq?faq=27"> *Toelichting terminologie </Link>
            </div>
          </div>

          <div className="graphContent">
            <TitleBlock title={config.draw.title} desc={config.draw.desc} />
            <DataSelectorWrapper>
              <div>
                <span className="selectorEle"></span>
                <span className="textWrappers">{data[0].name}</span>
              </div>
              <div>
                <span className="teel selectorEle"></span>
                <span className="textWrappers">
                  <select
                    value={datasetSelected}
                    onChange={(e) => {
                      setDataSet(e.target.value)
                    }}
                  >
                    {data.map((item) => {
                      if (item.alwaysSelected) {
                        return null
                      }
                      return (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </span>
              </div>
              <div className="smallPad">
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    processTimeSelection(
                      new Date(date).toISOString().split('T')[0],
                      new Date(endDate).toISOString().split('T')[0]
                    )
                  }}
                />
                <img src={CalIcon} alt="calendar" className="calIcon" />
              </div>
              <div className="smallPad">
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date)
                    processTimeSelection(
                      new Date(startDate).toISOString().split('T')[0],
                      new Date(date).toISOString().split('T')[0]
                    )
                  }}
                />
                <img src={CalIcon} alt="calendar" className="calIcon" />
              </div>
            </DataSelectorWrapper>

            <ButtonGroup>
              <Button
                selected={selectedItem === null}
                onClick={() => {
                  setTerm(null)
                  setSelected(null)
                }}
              >
                {config.allTime}
              </Button>
              {dateOptions.map((item) => (
                <Button
                  selected={item.term[2] === selectedItem}
                  onClick={() => {
                    setTerm(item.term)
                    setSelected(item.term[2])
                  }}
                  key={item.term}
                >
                  {item.label}
                </Button>
              ))}

              {selectedItem === 'custom' && (
                <Button selected={selectedItem === 'custom'} onClick={() => {}}>
                  {config.custom}
                </Button>
              )}
            </ButtonGroup>

            <Plot
              data={drawDataToRender}
              onRelayout={(e) => {
                clearTimeout(timelineTO)
                timelineTO = setTimeout(() => {
                  processTimeLine(e)
                }, 500)
              }}
              config={{
                displayModeBar: false,
                displaylogo: false,
                responsive: true,
                modeBarButtonsToRemove: [
                  'select2d',
                  'lasso2d',
                  'sendDataToCloud',
                  'toggleSpikelines',
                  'resetViewMapbox',
                  'hoverClosestCartesian',
                  'hoverCompareCartesian',
                ],
              }}
              layout={{
                xaxis: {
                  type: 'date',
                  rangeslider: { thickness: 0.2 },
                },
                yaxis: {
                  autorange: true,
                  ticksuffix: ' %',
                  range: [0, 298],
                  type: 'linear',
                  title: {
                    text: 'Drawdown (%)',
                    standoff: 40,
                  },
                },
                font: {
                  family: `'Lato', sans-serif`,
                  color: 'rgba(0,0,0,0.4)',
                  size: '13',
                },
                margin: {
                  pad: '12',
                },
                showlegend: false,
              }}
              useResizeHandler
            />

            <div className="graphFaq">
              <Link to="/faq?faq=27"> *Toelichting terminologie </Link>
            </div>
          </div>
        </>
      )}
    </Wrapper>
  )
}

export default App
