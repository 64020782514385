import React from 'react'

import { StepStyling, H3, Button } from '../../views/step.views'

const Step = ({ goToStep, text, flowType, type }) => {
  return (
    <StepStyling>
      <div className="contentArea textLeft">
        <H3> {text.title} </H3>
        <div className="descInfo">
          {flowType === 'offline'
            ? text.descOffline
            : type === 'person'
            ? text.descOnline
            : text.descOnlineEntity}
        </div>

        {flowType === 'offline' ? (
          <Button notFullWidth handleClick={() => goToStep(5)}>
            {' '}
            {text.button}{' '}
          </Button>
        ) : (
          ''
        )}
      </div>
    </StepStyling>
  )
}
export default Step
