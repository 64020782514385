import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import userSvg from '../../assets/User.svg'
import { ReactComponent as Chevron } from '../../assets/Chev_Right.svg'
import {
    MobileMenuItemPadding,
    HeaderMenuButtonPadding,
} from '../elements/padding'
import { PrimaryBigButton } from '../elements/button'
import TopBar from './topbar'
import Width from '../elements/width'
import CloseMenu from '../../assets/Close.png'

const MobileMenuPadding = styled.div`
  height: 100%;
  padding: 18px 0;
  overscroll-behavior: contain;
`

const MobileMenuWrapper = styled.div`
  display: none;
  background-color: #f5f5f3;
  height: 0;
  .mobileWidth {
    height: calc(100% - 50px);
    position: relative;
  }

  .mobileMenuItem {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    margin: 0 1.5rem;

    @media screen and (max-width: 720px) {
      margin: 0;
    }

    a {
      font-weight: 600;
    }

    svg {
      height: 1rem;
      width: 1rem;
      float: right;
    }

    &:hover,
    &:active {
      color: #097490;
    }

    &:hover {
      svg polygon {
        fill: #097490;
      }
    }

    &:first-child {
      border-top: 0;
    }
  }

  .bigMobileMenuButton {
    div {
      color: #60526a;
    }
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
  }

  .userSvg {
    width: auto;
    height: 14px;
    margin-bottom: -4px;
  }

  .mobileMenuButtonWrapper {
    text-align: right;
  }

  @media screen and (max-width: 990px) {
    .buttonAppointmentWrapper,
    .menuWrapper {
      display: none;
    }
    overflow: hidden;

    .mobileMenuContainer {
      overflow-y: auto;
      overscroll-behavior: contain;
      height: calc(100% - 84px);
    }

    &.open {
      height: 100%;
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      width: 0;
      min-height: 100vh;
      width: 100vw;
      z-index: 9999;
    }

    .userSvg {
      width: auto;
      height: 1rem;
      margin-bottom: -4px;
      margin-right: 1rem;
    }
    .menuContainer {
      height: calc(100% - 50px);
    }
    .menuText {
      p,
      img {
        display: inline-block;
        vertical-align: middle;
      }
      p {
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0.5;
        padding: 0;
        margin: 0;
        margin-top: 5px;
      }
      img {
        height: 22px;
        margin-left: 12px;
      }
    }
  }
`

const MobileMenu = ({ mobileMenuExpand, setMobileMenuExpand, mockItems }) => {
    return (
        <MobileMenuWrapper
            className={`mobileMenuWrapper ${mobileMenuExpand ? 'open' : ''}`}
        >
            <TopBar />
            <Width className="mobileWidth">
                <MobileMenuPadding>
                    <HeaderMenuButtonPadding className="mobileMenuButtonWrapper">
                        <PrimaryBigButton
                            paddingVariant="icon"
                            variant="quinary"
                            notFullWidth={true}
                            handleClick={() => {
                                setMobileMenuExpand(!mobileMenuExpand)
                            }}
                        >
                            <span className="menuText">
                                <p>Sluiten</p>
                                <img src={CloseMenu} alt="" />
                            </span>
                        </PrimaryBigButton>
                    </HeaderMenuButtonPadding>
                    <div className="mobileMenuContainer">
                        {mockItems.map((item, index) => {
                            return (
                                <Link
                                    to={{
                                        pathname: item.link,
                                    }}
                                    key={`mobile-menu-item-${index}`}
                                    className="mobileMenuItem"
                                    onClick={() => {
                                        setMobileMenuExpand(!mobileMenuExpand)
                                    }}
                                >
                                    <div key={`mobile-menu-item-${index}`}>
                                        <MobileMenuItemPadding>
                                            {item.text}
                                            <Chevron />
                                        </MobileMenuItemPadding>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                    <div className="bigMobileMenuButton">
                        <PrimaryBigButton
                            paddingVariant="icon"
                            variant="secondary"
                            className="bigMobileMenuButton"
                            handleClick={() => {
                                window.open('https://vincicapital.assetcareportal.com/', '_blank')
                            }}
                        >
                            <img className="userSvg" src={userSvg} alt="" /> Client Area
                        </PrimaryBigButton>
                    </div>
                </MobileMenuPadding>
            </Width>
        </MobileMenuWrapper>
    )
}
export default MobileMenu
