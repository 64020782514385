import React from "react";
import styled from "styled-components";
import { PrimarySmallButton } from "./button";

const Wrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999999999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    overflow-y: scroll;

    .popupContentContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 50%;
        max-height: 100vh;
        background-color: white;
        padding: 20px   40px;
        .closeButton {
            cursor: pointer;
            right: 40px;
            position: absolute;
            z-index: 1;
            padding: 10px;
        }

        .childrenContainer {
            margin-top: 10px;
        }

        @media screen and (max-width: 890px) {
            top: 0;
            left: 0;
            transform: none;
            width: calc(100% - 40px);
            margin: 20px;
        }
    }
    ${(props) =>
        props.hide
            ? `
        z-index: -99999;
        height: 0;
        width: 0;
        opacity: 0;
    `
            : ``}

    @media screen and (max-width: 1000px) {
        .popupContentContainer {
            width: 90%;
        }
    }
`

const PopupModal = ({
    handleClose,
    children,
    show = true,
}) => {

    return (
        <Wrapper hide={!show}>
            <div className="popupContentContainer">
                <div className="closeButton">
                    <PrimarySmallButton handleClick={handleClose}>
                        Sluiten
                    </PrimarySmallButton>
                </div>
                <div className="childrenContainer">
                    {children}
                </div>
            </div>
        </Wrapper>
    )
}

export default PopupModal
