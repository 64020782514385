import React from 'react'
import styled from 'styled-components'
import PageWrapper from '../core/modules/pageWrapper'
import Width from '../core/elements/width'
import FAQModule from '../core/modules/faqBlock'

const FAQPageWrapper = styled.div``

const FAQPage = () => {
    return (
        <FAQPageWrapper>
            <PageWrapper>
                <Width className="scrollToMe">
                    <FAQModule isFAQPage={true} />
                </Width>
            </PageWrapper>
        </FAQPageWrapper>
    )
}
export default FAQPage
