export const contactInfo = {
    phone: '020 - 210 10 12',
    email: 'info@vincicapital.nl',
    kvk: '77473558',
    btw: 'NL861018254B01',
}

export const addressInfo = {
    street: 'Prinsengracht 769',
    postalCode: '1017 JZ',
    city: 'Amsterdam',
}
