import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Width from '../elements/width'
import halfBg from '../../assets/how_bg_asset.png'
import { itemTitleProps } from '../elements/typography'
import { ReactComponent as PerformanceIcon } from '../../assets/Performance_Icon.svg'
import { ReactComponent as FactsheetIcon } from '../../assets/Factsheet_Icon.svg'
import { innerHtml } from '../utils/useInnerHTML'
import { useDocumentApi } from '../../APIs/useApi'
import Loader from '../elements/loader'
// import buildCorrectLocalHref from '../utils/linkBuilder'

const DownloadsWrapper = styled.div`
  background: #f3f3f1;
  position: relative;

  .topBackground {
    background-image: url('${halfBg}');
    background-position: center center;
    background-size: contain;
  }

  .contentAreabg {
    margin: 0 120px 80px;
    @media screen and (min-width: 1900px) {
      margin: 0 calc(120px + 0.14vw) calc(80px + 0.14vw);
    }
  }

  .pBlocks {
    white-space: nowrap;
    padding: 40px 0;

    > div {
      display: inline-block;
      vertical-align: middle;
      &:first-child {
        margin-right: 25px;
      }

      &:last-child {
        margin-left: 25px;
      }
    }

    p {
      line-height: 1.8rem;
    }

    @media screen and (min-width: 1900px) {
      padding: calc(40px + 0.14vw) 0;

      > div {
        &:first-child {
          margin-right: calc(25px + 0.14vw);
        }
        &:last-child {
          margin-left: calc(25px + 0.14vw);
        }
      }
    }
  }

  @media (max-width: 720px) {
    .topBackground {
      background-position-y: 676px;
      background-size: auto;
    }

    .contentAreabg {
      margin: 0;
    }

    .pBlocks {
      white-space: normal;
      padding: 40px 0;
      width: 100%;

      > div {
        display: block;

        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
`

const InfoItem = styled.div`
  cursor: pointer;
  background-color: #9ec5ce;
  ${(props) =>
        props.variant === 'secondary' ? 'background-color: #B9B4BB;' : ''};
  padding: 44px 22px 44px 88px;
  border-radius: 3px;
  position: relative;
  width: calc(50% - 25px);
  h3 {
    color: #32728d;
    ${(props) => (props.variant === 'secondary' ? 'color: #5e5368;' : '')};
  }

  p {
    color: #fff;
    font-size: 16px;
    margin: 5px 0;
  }

  p,
  h3 {
    max-width: 360px;
    white-space: break-spaces;
  }

  .innerHtml {
    h1,
    h2,
    h3 {
      ${itemTitleProps};
      color: #097490;
    }
  }

  &.secondaryItem {
    .innerHtml {
      h1,
      h2,
      h3 {
        ${itemTitleProps};
        color: #55485f;
      }
    }
  }

  .icon-container {
    pointer-events: none;
    width: 80px;
    height: 80px;
    margin: 0;
    border-radius: 50%;
    background-color: #32728d;
    position: absolute;
    left: -20px;
    top: calc(50% - 40px);
    ${(props) =>
        props.variant === 'secondary' ? 'background-color: #5e5368;' : ''};

    .icon {
      margin: 0;
      padding: 20px;
      width: 100%;
      height: 100%;
      position: relative;

      svg {
        height: 100%;
        width: 100%;
        g polygon,
        path {
          fill: #fff;
        }
      }
    }

    @media screen and (min-width: 1900px) {
      width: calc(80px + 0.14vw);
      height: calc(80px + 0.14vw);

      left: calc(-20px - 0.07vw);
      top: calc(50% + 0.14vw - 40px);

      .icon {
        padding: calc(20px + 0.07vw);
      }
    }
  }

  @media screen and (min-width: 1900px) {
    padding: calc(44px + 0.14vw) calc(22px + 0.14vw) calc(44px + 0.14vw)
      calc(88px + 0.14vw);
    border-radius: calc(3px + 0.14vw);

    p {
      max-width: calc(80% + 0.14vw);
      margin: calc(5px + 0.14vw) 0;
    }
    h3 {
      max-width: calc(80% + 0.14vw);
    }
  }

  @media (max-width: 720px) {
    padding: 12px 22px 12px 70px;
    margin-bottom: 18px;
    border-radius: 3px;
    position: relative;
    width: 100% !important;

    p {
      font-size: 15px;
    }

    p,
    h3 {
      max-width: 100%;
    }

    .icon-container {
      width: 68px;
      height: 68px;
      margin: 0;
      position: absolute;
      left: -10px;
      top: calc(50% - 34px);

      .icon {
        margin: 0;
        padding: 20px;
        width: 100%;
        height: 100%;
        position: relative;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
`

const ItemBlock = ({ item, variant, nr }) => {
    const history = useHistory()
    let doc_nr = item.doc || '8'
    const { data, isLoading } = useDocumentApi(
        item.doc,
        `/api/v2/documents/${doc_nr}/`
    )

    if (isLoading) return <Loader />

    const handleClick = () => {
        if (nr === 2) {
            history.push('/performance')
        } else {
            if (!item.doc) {
                return
            }
            window.open(data?.meta?.download_url, '_blank')
        }
    }
    return (
        <InfoItem
            onClick={handleClick}
            className={`${nr === 2 ? 'secondaryItem' : ''} textBlockSection`}
            key={`info-item-${nr}`}
            variant={variant}
        >
            <div className="icon-container">
                <div className="icon">
                    {nr === 1 ? <FactsheetIcon /> : <PerformanceIcon />}
                </div>
            </div>
            {innerHtml(item.text)}
        </InfoItem>
    )
}

let handleResponsive = null
const DownloadsModule = ({ content }) => {
    const elementref = React.useRef()
    const containerReference = React.useRef()
    const { type, value } = content

    const handleContentSizingTextBlock = () => {
        const elements = containerReference?.current?.querySelectorAll(
            '.textBlockSection'
        )

        if (elements && elements.length > 0) {
            const newDom = [...elements]
            let minHeight = 0

            newDom.forEach((element) => {
                element.removeAttribute('style')
            })

            newDom.forEach((element) => {
                if (element.offsetHeight > minHeight) {
                    minHeight = element.offsetHeight
                }
            })

            newDom.forEach((element) => {
                element.setAttribute('style', `min-height: ${minHeight}px ;`)
            })
        }
    }

    React.useEffect(() => {
        handleContentSizingTextBlock()

        const handleResizeTextBlock = () => {
            clearTimeout(handleResponsive)

            handleResponsive = setTimeout(() => {
                handleContentSizingTextBlock()
            }, 35)
        }

        // Event listener
        window.removeEventListener('resize', handleResizeTextBlock)
        window.addEventListener('resize', handleResizeTextBlock)

        return () => {
            window.removeEventListener('resize', handleResizeTextBlock)
        }
    }, [])

    if (!type == 'twocolumn') return null
    return (
        <DownloadsWrapper>
            <div className="topBackground" ref={containerReference}>
                <div ref={elementref} className="contentArea">
                    <Width>
                        <div className="contentAreabg">
                            <div className="pBlocks">
                                {value?.left_cta_buttons && (
                                    <ItemBlock
                                        item={value.left_cta_buttons[0]}
                                        variant={'primary'}
                                        nr={1}
                                    />
                                )}
                                {value?.right_cta_buttons && (
                                    <ItemBlock
                                        item={value.right_cta_buttons[0]}
                                        variant={'secondary'}
                                        nr={2}
                                    />
                                )}
                            </div>
                        </div>
                    </Width>
                </div>
            </div>
        </DownloadsWrapper>
    )
}

export default DownloadsModule
