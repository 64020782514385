import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useHistory, Link, useRouteMatch } from 'react-router-dom'

import logoSvg from '../../assets/Logo.svg'
import userSvg from '../../assets/User.svg'
import MenuBars from '../../assets/Menu.png'

import {
    MenuPadding,
    MenuItemPadding,
    HeaderMenuButtonPadding,
} from '../elements/padding'
import { PrimaryBigButton } from '../elements/button'
import Width from '../elements/width'
import MobileMenu from './menuMobileExpanded'

const GlobalStyle = createGlobalStyle`
  body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`

const MenuWrapper = styled.div`
  .logo {
    width: auto;
    height: 60px;
    cursor: pointer;

    @media (min-width: 990px) {
      &.homepageLogo {
        position: relative;
        top: 132px;
        left: 60px;
        z-index: 9999;
      }
    }

    @media (min-width: 1900px) {
      height: calc(60px + 1vw);

      &.homepageLogo {
        left: calc(60px + 1vw);
      }
    }

    @media (max-width: 990px) {
      height: 38px;
    }

    &.menu-open {
      display: none;
    }
  }

  .menuItem {
    display: inline-block;
    vertical-align: top;

    &:hover a,
    a.active {
      color: #097490;
    }

    a.active {
      border-bottom: 3px solid rgba(9, 116, 144, 0.2);
      display: block;
      padding-bottom: 8px;
    }

    &:hover a {
      border-bottom: 3px solid rgba(9, 116, 144, 0.2);
      display: block;
      padding-bottom: 8px;
    }
  }

  .menuWrapper,
  .buttonWrapper,
  .buttonAppointmentWrapper {
    float: right;
  }
  .menuButtonWrapper {
    display: none;
    height: 0;
  }

  .userSvg {
    width: auto;
    height: 14px;
    margin-bottom: -4px;
  }

  .clearboth {
    display: inline-block;
    clear: both;
  }

  @media screen and (max-width: 990px) {
    .buttonAppointmentWrapper,
    .menuWrapper {
      display: none;
    }

    .menuButtonWrapper {
      display: block;
      float: right;
      height: auto;

      .menuText {
        p,
        img {
          display: inline-block;
          vertical-align: middle;
        }
        p {
          text-transform: uppercase;
          font-size: 12px;
          opacity: 0.5;
          padding: 0;
          margin: 0;
          margin-top: 5px;
        }
        img {
          height: 22px;
          margin-left: 12px;
        }
      }
    }

    .buttonWrapper {
      &.menu-open {
        display: none;
      }
    }

    .userSvg {
      height: 19px;
      padding: 1px 0;
    }
  }
`

export const mockItems = [
    {
        text: 'Over RAMM',
        link: '/over-ramm',
    },
    {
        text: 'Performance',
        link: '/performance',
    },
    {
        text: 'Hoe participeren?',
        link: '/participeren',
    },
    {
        text: 'Blog',
        link: '/blog',
    },
    {
        text: 'FAQ',
        link: '/faq',
    },
    {
        text: 'Contact',
        link: '/contact',
    },
]

const LinkItem = ({ index, item }) => {
    let match = useRouteMatch({
        path: item.link,
    })

    return (
        <div key={`menu-item-${index}`} className="menuItem">
            <MenuItemPadding>
                <Link
                    to={{
                        pathname: item.link,
                    }}
                    className={match ? 'active' : ''}
                >
                    {item.text}
                </Link>
            </MenuItemPadding>
        </div>
    )
}

const Menu = ({ logoType, handleOpenModal }) => {
    const history = useHistory()
    const [mobileMenuExpand, setMobileMenuExpand] = React.useState(false)

    return (
        <MenuWrapper>
            <Width>
                <MenuPadding>
                    <img
                        className={`logo ${logoType === 'home' ? 'homepageLogo' : ''} ${
                            mobileMenuExpand ? 'menu-open' : ''
                        }`}
                        src={logoSvg}
                        alt="Ramm Logo"
                        onClick={() => {
                            history.push('/')
                        }}
                    />

                    <HeaderMenuButtonPadding className="menuButtonWrapper">
                        <PrimaryBigButton
                            paddingVariant="icon"
                            variant="quinary"
                            handleClick={() => {
                                setMobileMenuExpand(!mobileMenuExpand)
                            }}
                        >
                            <span className="menuText">
                                <p>MENU</p>
                                <img src={MenuBars} alt="" />
                            </span>
                        </PrimaryBigButton>
                    </HeaderMenuButtonPadding>
                    <HeaderMenuButtonPadding className="buttonAppointmentWrapper">
                        <PrimaryBigButton
                            handleClick={() => {
                                handleOpenModal()
                            }}
                        >
                          Ontvang brochure
                        </PrimaryBigButton>
                    </HeaderMenuButtonPadding>
                    <HeaderMenuButtonPadding
                        className={`buttonWrapper ${mobileMenuExpand ? 'menu-open' : ''}`}
                    >
                        <PrimaryBigButton paddingVariant="icon" variant="secondary" handleClick={() => {
                            window.open('https://vincicapital.assetcareportal.com/', '_blank')
                        }}>
                            <img className="userSvg" src={userSvg} alt="" />
                        </PrimaryBigButton>
                    </HeaderMenuButtonPadding>
                    <div className="clearboth"></div>
                    <div className="menuWrapper">
                        {mockItems.map((item, index) => {
                            return <LinkItem key={item.link} item={item} index={index} />
                        })}
                    </div>

                    {mobileMenuExpand && <GlobalStyle />}
                    {mobileMenuExpand && (
                        <MobileMenu
                            mockItems={mockItems}
                            mobileMenuExpand={mobileMenuExpand}
                            setMobileMenuExpand={setMobileMenuExpand}
                        />
                    )}
                </MenuPadding>
            </Width>
        </MenuWrapper>
    )
}
export default Menu
