export const config = {
  options: {
    previous: 'Terug naar de vorige stap',
  },

  steps: {
    newCustomer: {
      title: 'Bent u een nieuwe participant?',

      option1: 'Nieuwe participant',

      option2: 'Bestaande participant',

      tip:
        'Participeren in het RAMM Global Assets Fund kan heel eenvoudig door de bovenstaande stappen te doorlopen.',
    },

    onlineOffline: {
      title: 'Digitaal ondertekenen?',

      option1: 'Ja',

      option2: 'Nee',

      tip:
        "Wanneer u kiest voor 'Ja' dan vraagt u in de volgende stappen het inschrijfformulier aan en ontvangt u een verzoek per e-mail de formulieren digitaal te ondertekenen, u hoeft in dat geval de stukken niet te printen. Indien u kiest voor 'Nee', dan download u in de volgende stap het inschrijfformulier en kunt u deze zelf invullen, printen en ondertekenen.",
    },

    offlineSign: {
      button: 'Verder',

      title: 'Download hier uw inschrijfformulier',

      doc1Title: 'Inschrijfformulier Natuurlijk Persoon',

      doc2Title: 'FATCA formulier',

      doc1: 'Dit is het inschrijfformulier voor een natuurlijk persoon.',

      doc2:
        'Vul ook het FATCA formulier in indien er meerdere rekeninghouders zijn.',

      doc1Link: '/documents/3/Inschrijfformulier_Natuurlijk_Persoon.pdf',

      doc2Link:
        '/documents/5/Bijlage_1_Formulier_bepalen_belastingstatus_zs2fc07.pdf',

      tip:
        'Download en vul het inschrijfformulier en de eventueel extra formulieren in. Indien u akkoord bent verzoeken wij u vriendelijk de documenten voor akkoord te ondertekenen.',
    },

    offlineSignEntity: {
      button: 'Verder',

      title: 'Download hier uw inschrijfformulier',

      doc1Title: 'Inschrijfformulier Rechtspersoon',

      doc2Title: 'Extra FATCA formulier',

      doc3Title: 'Extra formulier Herkomst van Vermogen',

      doc1: 'Dit is het inschrijfformulier voor een rechtspersoon.',

      doc2:
        "U gebruikt het extra FATCA formulier in het geval er meerdere UBO's zijn.",

      doc3:
        "U gebruikt het extra formulier Herkomst van Vermogen in het geval er meerdere UBO's zijn.",

      tip:
        'Download en vul het inschrijfformulier en de eventueel extra formulieren in. Indien u akkoord bent verzoeken wij u vriendelijk de documenten voor akkoord te ondertekenen.',

      doc1Link: '/documents/4/Inschrijfformulier_Rechtspersoon_A02A3Fo.pdf',

      doc2Link:
        '/documents/5/Bijlage_1_Formulier_bepalen_belastingstatus_zs2fc07.pdf',

      doc3Link:
        '/documents/6/Bijlage_3_Verklaring_Herkomst_van_Vermogen_iJEYAwK.pdf',
    },

    existingCustomer: {
      doc1Title: 'Mutatieformulier',

      doc1:
        'U gebruikt dit formulier voor een mutatie die u wilt doorvoeren in het RAMM Global Assets Fund.',

      doc1Link: '/documents/7/Mutatieformulier_Y4tOkJp.pdf',

      title: 'Vervolgstortingen en onttrekkingen',

      desc:
        'Bent u al participant, dan kunt u eenvoudig participaties bijkopen (voor minimaal €10,000.-) of verkopen (mits minimaal €100,000.- aan waarde resteert). Hiervoor verzoeken wij u gebruik te maken van het mutatieformulier en deze ondertekend aan ons retourneren.',
    },

    whatAreYou: {
      title: 'Wat bent u?',

      option1: 'Natuurlijk Persoon',

      option2: 'Rechtspersoon',

      tip: '',
    },

    formPerson: {
      title: 'Vul onderstaand formulier in',

      fields: {
        firstName: 'Voornaam:',

        lastName: 'Naam:',

        email: 'E-mail:',

        phone: 'Telefoon:',
      },

      fieldsPlaceholders: {
        firstName: 'Vul hier uw Voornaam in',

        lastName: 'Vul hier uw Achternaam in',

        email: 'Vul hier uw E-mail in',

        phone: 'Vul hier uw telefoonnummer in',
      },

      tip: '',

      button: 'Verstuur',
    },

    formEntity: {
      title: 'Vul onderstaand formulier in',

      fields: {
        entityName: 'Naam rechtspersoon',

        firstName: 'Voornaam:',

        lastName: 'Achternaam:',

        email: 'E-mail:',

        phone: 'Telefoon',
      },

      fieldsPlaceholders: {
        entityName: 'Vul hier de naam van de Rechtspersoon in',

        firstName: 'Vul hier uw Voornaam in',

        lastName: 'Vul hier uw Achternaam in',

        email: 'Vul hier uw E-mail in',

        phone: 'Vul hier uw telefoonnummer in',
      },

      tip: '',

      button: 'Verstuur',
    },

    nextStep1: {
      title: 'Controleer de aanvullende benodigdheden',

      descOnline: `Bedankt voor het invullen van uw gegevens, u ontvangt zsm maar uiterlijk binnen twee werkdagen uw inschrijfformulier op het opgegeven e-mailadres met het verzoek deze te ondertekenen. Mocht u eerder vragen of opmerkingen hebben, aarzel dan niet contact met ons op te nemen.`,

      descOffline: `Onderaan pagina twee van het inschrijfformulier treft u een opsomming van de benodigde

      stukken voor het openen van uw rekening, controleer of u alle benodigdheden heeft verzameld en

      stuur deze samen met de ondertekende formulieren uit de vorige stap op naar info@vincicapital.nl.`,

      descOnlineEntity: `Bedankt voor het invullen van uw gegevens, wij zullen zsm maar uiterlijk binnen twee werkdagen contact met u opnemen om de juiste vertegenwoordigers en UBO\'s een uitnodiging ter ondertekening van de benodigde stukken te sturen. Mocht u eerder vragen of opmerkingen hebben, aarzel dan niet contact met ons op te nemen.`,

      button: 'Verder',
    },

    nextStep2: {
      title: 'Bevestig uw aankoop',

      desc: `Binnen twee werkdagen ontvangt u bevestiging van uw inschrijving en een stortingsinstructie om

      uw aankoop af te ronden. Indien uw storting uiterlijk twee werkdagen voorafgaand aan de wekelijkse handelsdag is ontvangen, zal uw aankoop nog op deze handelsdag worden uitgevoerd.

      Per omgaande ontvangt u vervolgens een bevestiging van uw aankoop.`,

      buttonText: '',
    },

    anotherPerson: {
      title: 'Wilt u een mederekeninghouder toevoegen?',

      option1: 'Ja',

      option2: 'Nee',

      tip: '',
    },
  },
}
