import styled from 'styled-components'

const BackWrapper = styled.div`
  font-size: 0.9em;
  padding: 18px 0 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  span {
    line-height: 22px;

    &.backcontainer {
      svg {
        margin-right: 12px;
      }
    }
  }
`

export default BackWrapper
