import styled from 'styled-components'
import { createTheme } from '@material-ui/core/styles'

export default styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    display: block;
  }

  div {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  .graphFaq {
    display: block;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 400;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
  }

  .errorInput {
    font-size: 0.8rem;
    color: red;
    padding: 4px 0 12px 0;
  }
`

export const materialUiTheme = () => {
    const theme = createTheme({})

    return theme
}
