import React from 'react'
import styled from 'styled-components'
import {
    TextWithImageBlockPadding,
    TextWithImageTextBlockPadding,
    BigSectionGraphContentBlockPadding,
} from '../elements/padding'
import { ScrollButton } from '../elements/button'
import {
    SectionTitle,
    P,
    ContactInfo,
    sectionTitleSmallProps,
} from '../elements/typography'
import ScrollIcon from '../../assets/scroll_icon.png'
import { getImageUrl } from '../utils/getImageUrl'
import { innerHtml } from '../utils/useInnerHTML'
import asset from '../../assets/Photo_Home@2x.jpg'
import { contactInfo } from '../utils/companyInfo'

const TextWithImageBlockWrapper = styled.div`
  .imageAsset,
  .textArea {
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: middle;

    h3,
    p {
      max-width: 80%;
      text-align: left;
    }

    > div > p {
      max-width: 500px;
      padding: 20px 0 16px;
      line-height: 1.8rem;

      @media screen and (min-width: 1900px) {
        max-width: calc(500px + 0.14vw);
        padding: calc(20px + 0.14vw) 0;
      }
    }

    .innerHtml {
      h1,
      h2,
      h3 {
        ${sectionTitleSmallProps};
        color: #949480;
        max-width: 80%;
      }
    }
  }

  .imageAsset {
    height: 40vh;
    overflow: hidden;
    min-height: 300px;
    max-height: 650px;

    @media screen and (min-width: 1900px) {
      min-height: calc(300px + 0.14vw);
      max-height: calc(650px + 0.14vw);
    }
  }

  .imageAsset img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .textArea {
    .button {
      img,
      span {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        height: 22px;
        margin-left: 9px;

        @media screen and (min-width: 1900px) {
          height: calc(22px + 0.14vw);
          margin-left: calc(9px + 0.14vw);
        }
      }
    }

    .extraContactInfo {
      display: block;
      padding: 22px 0;

      .contactColumn {
        display: inline-block;
        width: 50%;
        vertical-align: top;

        p {
          font-size: 1.1rem;
          max-width: 100%;
          margin: 8px 0;
        }
      }
    }
  }

  @media (max-width: 720px) {
    .imageAsset,
    .textArea {
      width: 100%;
      display: block;

      > div > h3,
      > div > span > h3 {
        font-size: 2.3rem;
        max-width: 100%;
      }

      .innerHtml {
        h1,
        h2,
        h3 {
          font-size: 2.3rem;
          max-width: 100%;
        }
      }

      > div > p {
        max-width: 100%;
        padding: 20px 0 8px;
        margin-top: 0;
        line-height: 1.5rem;
      }

      .extraContactInfo {
        padding: 30px 0;
        .contactColumn {
          p {
            margin: 6px 0;
            max-width: 100%;
          }
        }
      }
    }

    .imageAsset {
      left: -20px;
      width: 100vw;
    }

    .imageAsset img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .buttonSection {
      display: none;
    }
  }
`

let link = {
    href: '#',
    linkText: 'Scroll verder',
}

const TextWithImageBlockModule = ({
    title,
    intro,
    image,
    handleScroll = () => {},
}) => {
    const imageLocation = image ? getImageUrl(image.meta.download_url) : asset
    const altText = image ? image.title : ''
    if (!(title || intro || image)) return null
    return (
        <TextWithImageBlockWrapper>
            <TextWithImageBlockPadding>
                <div className="imageAsset">
                    <img src={imageLocation} alt={altText} />
                </div>
                <div className="textArea">
                    <TextWithImageTextBlockPadding noTopPadding>
                        {title && (
                            <SectionTitle sizeVariant="small" variant="secondary">
                                {title}
                            </SectionTitle>
                        )}
                        {innerHtml(intro)}

                        <div className="extraContactInfo">
                            <div className="contactColumn">
                                <ContactInfo>Telefoon</ContactInfo>
                                <P>{contactInfo.phone}</P>
                            </div>
                            <div className="contactColumn">
                                <ContactInfo>Mail</ContactInfo>
                                <P>{contactInfo.email}</P>
                            </div>
                        </div>

                        <BigSectionGraphContentBlockPadding className="buttonSection">
                            <ScrollButton notFullWidth variant="quarternary">
                                <div className="button" onClick={handleScroll}>
                                    <span>{link.linkText}</span>
                                    <img src={ScrollIcon} alt="" />
                                </div>
                            </ScrollButton>
                        </BigSectionGraphContentBlockPadding>
                    </TextWithImageTextBlockPadding>
                </div>
            </TextWithImageBlockPadding>
        </TextWithImageBlockWrapper>
    )
}

export default TextWithImageBlockModule
