import React from 'react'
import styled from 'styled-components'
import { innerHtml } from '../utils/useInnerHTML'
import {
    AboutPersonTextBlockPadding,
    AboutPersonBlockPadding,
} from '../elements/padding'
import { SectionTitle, P } from '../elements/typography'
import useApi from '../../APIs/useApi'
import { getImageUrl } from '../utils/getImageUrl'

const DesktopPersonsBlock = styled.div`
  display: none !important;
  @media screen and (min-width: 721px) {
    display: block !important;
  }
`
const MobilePersonsBlock = styled.div`
  display: none !important;
  @media screen and (max-width: 721px) {
    display: block !important;
  }
`

const AboutPersonnelBlockWrapper = styled.div`
  .person-info {
    width: 40%;
    float: right;

    &:nth-child(2) {
      padding-top: 100px;
      margin-right: 8vw;
    }

    &:nth-child(3) {
      margin-right: 30%;
      margin-top: 20px;

      @media screen and (min-width: 1100px) {
        width: 90%;
        margin-right: 10%;

        .imageAsset,
        .textArea {
          display: inline-block;
          vertical-align: center;
        }
        
        .imageAsset {
          width: 500px;
        }

        .textArea {
          width: calc(100% - 500px);
          padding-left: 50px;

          h3 {
            text-align: left;
          }
        }
      }
    }

    @media screen and (min-width: 1900px) {
      width: calc(40%);

      &:nth-child(2) {
        padding-top: calc(100px + 0.14vw);
        margin-right: calc(8vw + 0.14vw);
      }
    }
  }

  .textArea {
    p {
      padding: 20px 0;
    }
  }

  .imageAsset img {
    width: 100%;
    max-height: 30vh;
    min-height: 250px;
    object-fit: cover;
    object-position: center;
  }

  .floatClear {
    clear: both;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 720px) {
    .person-info {
      width: 100%;
      float: none;
      padding-bottom: 38px;

      .textArea {
        p {
          padding: 11px 0;
          margin: 0;
          line-height: 1.5rem;
        }
        h3 {
          font-size: 1.7rem;
        }
      }

      .imageAsset img {
        width: 100%;
        max-height: 250px;
        min-height: unset;
        object-fit: cover;
        object-position: center;
      }

      &:nth-child(2) {
        padding: 0;
        margin: 0;

        .textArea {
          p {
            padding: 11px 0 5px;
          }
        }
      }

      &:nth-child(3) {
        padding: 40px 0 0 0;
        margin: 0;

        .textArea {
          p {
            padding: 11px 0 5px;
          }
        }
      }
    }
  }
`

const PersonBlock = ({ item }) => {
    const { data: image, isLoading, isError } = useApi(
        `image-${item.image}`,
        `/api/v2/images/${item.image}/`
    )

    if (isLoading) return null
    if (isError) return null

    return (
        <div key={`about-person-${item.name}`} className="person-info">
            <div className="imageAsset">
                <img src={getImageUrl(image.meta.download_url)} alt={image.title} />
            </div>
            <div className="textArea">
                <AboutPersonTextBlockPadding>
                    <SectionTitle sizeVariant="small" variant="secondary">
                        {item.name || item.title}
                    </SectionTitle>
                    <P>{innerHtml(item.description)}</P>
                </AboutPersonTextBlockPadding>
            </div>
        </div>
    )
}

const MobileView = ({ data, RVA }) => {
    if (!data) {
        return null
    }

    const newData = data.filter((item) => {
        return item.name.length > 1
    })

    return (
        <MobilePersonsBlock className="mobileView">
            <PersonBlock item={newData[0]} key={`about-person-${newData[0].name}`} />
            <PersonBlock item={newData[1]} key={`about-person-${newData[1].name}`} />
            {RVA && <PersonBlock item={RVA} key={`about-person-RVA`} />}
            <div className="floatClear"></div>
        </MobilePersonsBlock>
    )
}

const DesktopView = ({ data, RVA }) => {
    if (!data) {
        return null
    }

    const newData = data.filter((item) => {
        return item.name.length > 1
    })

    return (
        <DesktopPersonsBlock className="desktopView">
            <PersonBlock item={newData[1]} key={`about-person-${newData[1].name}`} />
            <PersonBlock item={newData[0]} key={`about-person-${newData[0].name}`} />
            {RVA && <PersonBlock item={RVA} key={`about-person-RVA`} />}
            <div className="floatClear"></div>
        </DesktopPersonsBlock>
    )
}

const AboutPersonnelBlockModule = ({content, RVA}) => {
    const { value, type } = content

    if (!type == 'aboutus') {
        return null
    }

    return (
        <AboutPersonnelBlockWrapper>
            <AboutPersonBlockPadding>
                {value.mugshots && value.mugshots.length > 0 && (
                    <DesktopView data={value.mugshots} RVA={RVA?.value} />
                )}
                {value.mugshots && value.mugshots.length > 0 && (
                    <MobileView data={value.mugshots} RVA={RVA?.value} />
                )}
            </AboutPersonBlockPadding>
        </AboutPersonnelBlockWrapper>
    )
}

export default AboutPersonnelBlockModule
