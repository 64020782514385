// 3rd party modules
import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// Material UI - Used for extra element / modules such as form elements
import { ThemeProvider } from '@material-ui/core/styles'

// Internal modules
import ApplicationStyling, { materialUiTheme } from './styles'
import ApplicationRouting from './router'
import ApplicationState from './context'
import ApplicationApiState from './core/utils/api/apiStateContext'

// Assets
import listImage from './assets/Check.svg'

// Global styling
const GlobalStyle = createGlobalStyle`
  body, html {
    background: #f3f3f1;
    padding: 0 !important;
    margin: 0 !important;
    font-family: 'SailecRegular', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: rgba(0,0,0,0.65);
    font-size: 15px;
    
    #root {
      overflow-x: hidden;
      width: 100%;
    }
  }

  .icon {
    width: auto;
    height: 12px;
    margin-right: 12px;
  }

  a {
    color: rgba(0,0,0,0.65);
  }

  // Lists
  p {
    ul {
      padding: 30px 0;
  
      li {
        min-height: 20px;
        line-height: 30px;
        padding-left: 60px !important;
        background-image: url('${listImage}');
        background-position: left center;
        background-size: 35px;
        background-repeat: no-repeat;
        font-family: 'SailecMedium';
        font-size: 1.1rem;
        font-weight: 400;
        color: #5e5368;
        display: block;
        margin: 12px 0;
      }
    }
  }

  @media screen and (min-width: 1900px) {
    body, html {
      font-size: calc(15px + 0.14vw);
    }

    p {
      ul {
        padding: calc(30px + 0.14vw) 0;
    
        li {
          min-height: calc(20px + 0.14vw);
          line-height: calc(30px + 0.14vw);
          padding-left: calc(40px + 1.14vw) !important;
          background-size: auto 70%;
          margin: 12px 0;
        }
      }
    }
  }

  .cookie-block {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    background: rgb(94, 83, 104);
    color: #fff;
    padding: 32px 0;

    > div {
      > div {
        width: 70%;
        display: inline-block;
        vertical-align: top;

        span.text {
          display: block;
          padding: 8px 0;
        }

        &:last-child {
          width: 30%;
        }
  
        > div {
          float: right;
        }
      }
    }

    @media (max-width: 700px) {
      > div {
        > div {
          width: 100%;
          display: block;

          &:last-child {
            width: 100%;
          }
  
          > div {
            float: none;
          }        
        }
      }
    }
  }

  .react-datepicker {
    span {
      overflow: initial !important;
    }
  }
`

// Main application wrapper
// Here we add our global styling, set global context and routing.
// We also use this wrapper inside storybook, to auto inherit all our state
// children will only be passed in storybook, otherwise it will load the whole app.
const RouterWrapper = ({ children }) => {
    const theme = materialUiTheme()

    return (
        <Router>
            <Switch>
                <Route path="*">
                    <ApplicationStyling>
                        <ThemeProvider theme={theme}>
                            <ApplicationApiState
                                CSRFTokenEndpoint="csrftoken"
                                CSRFTokenHeader="X-CSRFToken"
                            >
                                <ApplicationState>
                                    <GlobalStyle />
                                    {!children && <ApplicationRouting />}
                                    {children}
                                </ApplicationState>
                            </ApplicationApiState>
                        </ThemeProvider>
                    </ApplicationStyling>
                </Route>
            </Switch>
        </Router>
    )
}

export default RouterWrapper
