import React from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'
import { useHistory } from 'react-router-dom'
import Loader from '../core/elements/loader'

import PageWrapper from '../core/modules/pageWrapper'
import Width from '../core/elements/width'
import { P, SectionTitle } from '../core/elements/typography'
import asset from '../assets/Photo_Home@2x.jpg'
import arrowSvg from '../assets/Chev_Right.svg'
import { ArticleH3 } from '../core/elements/typography'
import Pagination from '../core/modules/pagination'
import {
    useBlogApi,
    useBlogArticlesApi,
    useBlogArticleApi,
} from '../APIs/useApi'
import { innerHtml } from '../core/utils/useInnerHTML'
import { getImageUrl } from '../core/utils/getImageUrl'

const articlesPerPage = 20

const BlogpageWrapper = styled.div`
  p.center {
    text-align: center;
    padding: 0 80px;

    span > p {
      padding: 0;
      margin: 0;
    }
  }

  .titleSection {
    padding: 40px 0;
  }

  h3 {
    text-align: center;
  }

  .content {
    padding: 0 7vw;

    div.card {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 40px;
      box-sizing: border-box;

      &:nth-child(even) {
        padding-left: 20px;
      }

      &:nth-child(odd) {
        padding-right: 20px;
      }
    }
  }

  .blogListWrapper {
    padding-bottom: 8vw;
  }

  @media screen and (max-width: 720px) {
    p.center {
      padding: 0;
      text-align: left;
    }

    .titleSection {
      padding: 0 0 40px;
    }

    .content {
      padding: 0;
      margin-bottom: 38px;

      div.card {
        width: 100%;
        display: block;
        margin-bottom: 18px;

        &:nth-child(even) {
          padding-left: 0;
        }

        &:nth-child(odd) {
          padding-right: 0;
        }
      }
    }

    .blogListWrapper {
      padding-bottom: 56px;
    }
  }
`

const BlogCardWrapper = styled.div`
  cursor: pointer;
  .imageHolder {
    width: 100%;
    height: 20vh;
    min-height: 300px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .cardTitle {
    padding: 30px;
    background: #fff;

    h3 {
      text-align: left;
      margin: 0;
      padding: 0;
    }

    > div {
      position: relative;
      padding-right: 80px;

      .arrowHolder {
        background: #e6f1f3;
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;

        img {
          position: absolute;
          top: 50%;
          height: 10px;
          width: auto;
          left: 16px;
          margin-top: -5px;
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    .imageHolder {
      width: 100%;
      height: 170px;
      min-height: unset;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }

    .cardTitle {
      padding: 15px 20px;

      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.4rem;
      }

      > div {
        position: relative;
        padding-right: 80px;

        .arrowHolder {
          width: 30px;

          img {
            left: 12px;
          }
        }
      }
    }
  }
`

export const BlogCard = ({ data: item }) => {
    const { data, isLoading, isError } = useBlogArticleApi(
        `blogArticle-${item.id}`,
        `/api/v2/pages/${item.id}`
    )

    if (isLoading) return <Loader />
    if (isError) return null

    const image = data.lead_image
        ? getImageUrl(data.lead_image.meta.download_url)
        : asset

    return (
        <BlogCardWrapper
            onClick={() => {
                window.location.href = `/blog/${item.id}`
            }}
        >
            <div className="imageHolder">
                <img src={image} alt={item.title} />
            </div>
            <div className="cardTitle">
                <div>
                    <ArticleH3 variant="secondary"> {item.title} </ArticleH3>
                    <div className="arrowHolder">
                        <img src={arrowSvg} alt={item.title} />
                    </div>
                </div>
            </div>
        </BlogCardWrapper>
    )
}

const ArticleSection = () => {
    const [page, setPage] = React.useState(1)
    const history = useHistory()

    // @todo update fetched articles when changing page
    const { data, isLoading, isError } = useBlogArticlesApi(
        `/api/v2/pages/?child_of=11&order=-first_published_at`
    )
    if (isLoading) return <Loader />
    if (isError) return null

    const changePage = (e, index) => {
        setPage(index)
    }

    const displayData =
    data.items &&
    data.items.filter((item, i) => {
        return i >= (page - 1) * articlesPerPage && i < page * articlesPerPage
    })
    const paginationCount = Math.ceil(data.meta.total_count / articlesPerPage)

    return (
        <>
            <div className="content">
                {displayData.map((item, index) => (
                    <div key={`blog-item-${index}`} className="card">
                        {' '}
                        <BlogCard data={item} history={history} />{' '}
                    </div>
                ))}
            </div>
            {paginationCount > 1 && (
                <Pagination
                    count={Math.ceil(data.meta.total_count / articlesPerPage)}
                    handleChange={changePage}
                    page={page}
                />
            )}
        </>
    )
}

const Blogpage = () => {
    const { data, isLoading, isError } = useBlogApi('/api/v2/pages/11/')
    if (isLoading) return <Loader />
    if (isError) return null

    return (
        <BlogpageWrapper>
            <MetaTags>
                {data?.meta && (
                    <title>
                        {data.meta.seo_title ? data.meta.seo_title : 'Blog'} - RAMM Funds
                    </title>
                )}
                {data?.meta?.search_description && (
                    <meta name="description" content={data.meta.search_description} />
                )}
            </MetaTags>
            <PageWrapper>
                <Width>
                    <div className="blogListWrapper">
                        <div className="titleSection">
                            <SectionTitle variant="secondary"> {data.title} </SectionTitle>
                            <P className="center">{innerHtml(data.intro)}</P>
                        </div>
                        <ArticleSection />
                    </div>
                </Width>
            </PageWrapper>
        </BlogpageWrapper>
    )
}
export default Blogpage
