import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { StepStyling, H3, Button } from '../../views/step.views'

const Step = ({ goToStep, text }) => {
  return (
    <StepStyling>
      <div className="contentArea">
        <H3> {text.title} </H3>

        <div className="buttonWrapper">
          <Button notFullWidth handleClick={() => goToStep(10)}>
            {' '}
            {text.option1}{' '}
          </Button>
          <Button notFullWidth handleClick={() => goToStep(4)}>
            {' '}
            {text.option2}{' '}
          </Button>
        </div>
        {text.tip && text.tip.length > 0 && (
          <div className="textArea tiparea">
            <FontAwesomeIcon icon={faInfoCircle} />
            {text.tip}
          </div>
        )}
      </div>
    </StepStyling>
  )
}
export default Step
