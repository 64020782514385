import React from 'react'
import styled from 'styled-components'
import { MenuPadding, ContentBlockPaddingBlock } from '../elements/padding'
import { ArticleH3 } from '../elements/typography'
import { PrimarySmallButton } from '../elements/button'
import { innerHtml } from '../utils/useInnerHTML'
import { useHistory } from 'react-router-dom'
import buildCorrectLocalHref from '../utils/linkBuilder'

const BlockWrapper = styled.div`
  padding: 30px 0;
  .blockElement {
    width: 33%;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    border-right: 1px solid rgba(0, 0, 0, 0.1);
    &:last-child {
      border-right: 0;
    }

    p {
      line-height: 1.8rem;
    }
  }

  @media (min-width: 1900px) {
    padding: calc(30px + 0.14vw) 0;
    .blockElement {
      padding: 1.8vw 0;
    }
  }

  @media (max-width: 940px) {
    .blockElement {
      width: 100%;
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 0;
      padding: 0;

      &:last-child {
        border-bottom: 0;
      }

      p {
        margin-block-end: 18px;
        margin-block-start: 16px;
      }
    }
  }
`

const BlockModule = ({ content }) => {
    const history = useHistory()
    const data = content.value
    const determineVariant = {
        1: 'primary',
        2: 'secondary',
        3: 'turnary',
        4: 'primary',
        5: 'secondary',
        6: 'turnary',
    }

    return (
        <BlockWrapper>
            <MenuPadding>
                {data &&
          data.map((item, index) => {
              return (
                  <div
                      key={`content-block-item-${item.title}`}
                      className={`blockElement ${
                          determineVariant[index + 1] || 'primary'
                      }`}
                  >
                      <ContentBlockPaddingBlock>
                          <ArticleH3 variant={determineVariant[index + 1] || 'primary'}>
                              {' '}
                              {item.title}{' '}
                          </ArticleH3>
                          {innerHtml(item.body)}
                          <PrimarySmallButton
                              variant={determineVariant[index + 1] || 'primary'}
                              handleClick={() => {
                                  const { url, internal } = buildCorrectLocalHref(item.link)

                                  if (internal) {
                                      history.push(url)
                                  } else {
                                      window.open(url, "_blank")
                                  }
                              }}
                              notFullWidth
                          >
                              {' '}
                    Lees meer{' '}
                          </PrimarySmallButton>
                      </ContentBlockPaddingBlock>
                  </div>
              )
          })}
            </MenuPadding>
        </BlockWrapper>
    )
}
export default BlockModule
