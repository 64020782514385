import React from 'react'
import { StepStyling, H3 } from '../../views/step.views'
const Step = ({ text }) => {
  return (
    <StepStyling>
      <div className="contentArea textLeft">
        <H3> {text.title} </H3>
        <div className="descInfo">{text.desc}</div>
      </div>
    </StepStyling>
  )
}
export default Step
