import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Chev } from '../../assets/Chev_Right.svg'

import {
    BigButtonPadding,
    BigButtonIconPadding,
    SmallButtonPadding,
    ScrollButtonPadding,
} from './padding'

const colorCodes = {
    primary: '#097490',
    secondary: '#d9d7d9',
    turnary: '#55485f',
    quarternary: '#D1E1E4',
    quinary: '#949480',
}

const colorCodesHover = {
    primary: '#065a6f',
    secondary: '#c4bbc4',
    turnary: '#6a5f74',
}

const PrimaryBigButtonWrapper = styled.div`
  background: ${(props) => colorCodes[props.variant]};
  color: ${(props) => (props.variant == 'quarternary' ? '#097490' : '#fff')};
  border-radius: 3px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
  text-align: center;

  span > p {
    margin: 0 !important;
    padding: 0 !important;
  }

  &:hover {
    background: ${(props) => colorCodesHover[props.variant]};
  }

  ${(props) =>
        props.flipped
            ? `
      background: ${colorCodesHover[props.variant]};
      &:hover {
        background: ${colorCodes[props.variant]};
      }
    `
            : ''}

  ${(props) => (props.notFullWidth ? 'display: inline-block !important;' : '')}
  ${(props) => (props.centerAlign ? 'margin: 0 auto !important;' : '')}

  @media (max-width: 720px) {
    display: block;
    ${(props) => (props.notFullWidth ? '' : 'width: 100%;')}
  }
`

const PrimaryBigButtonWrapperBTN = styled.button`
  background: ${(props) => colorCodes[props.variant]};
  border: 0;
  outline: 0;
  color: ${(props) => (props.variant == 'quarternary' ? '#097490' : '#fff')};
  border-radius: 3px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
  text-align: center;

  span > p {
    margin: 0 !important;
    padding: 0 !important;
  }

  &:hover {
    background: ${(props) => colorCodesHover[props.variant]};
  }

  ${(props) =>
        props.flipped
            ? `
      background: ${colorCodesHover[props.variant]};
      &:hover {
        background: ${colorCodes[props.variant]};
      }
    `
            : ''}

  ${(props) => (props.notFullWidth ? 'display: inline-block !important;' : '')}
  ${(props) => (props.centerAlign ? 'margin: 0 auto !important;' : '')}

  @media (max-width: 720px) {
    display: block;
    ${(props) => (props.notFullWidth ? '' : 'width: 100%;')}
  }
`

const colorSmallCodes = {
    primary: '#dfe8e8',
    secondary: '#e6e4e5',
    turnary: '#ebebe7',
}

const colorSmallCodesHover = {
    primary: '#c0cfcf',
    secondary: '#d5c9cf',
    turnary: '#d5d5c5',
}

const colorSmallCodesText = {
    primary: '#32728d',
    secondary: '#5e5368',
    turnary: '#949482',
}

const PrimarySmallButtonWrapper = styled.div`
  background: ${(props) => colorSmallCodes[props.variant]};
  color: ${(props) => colorSmallCodesText[props.variant]};
  border-radius: 3px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
  text-align: center;

  span > p {
    margin: 0 !important;
    padding: 0 !important;
  }

  &:hover {
    background: ${(props) => colorSmallCodesHover[props.variant]};
  }

  ${(props) => (props.notFullWidth ? 'display: inline-block !important;' : '')}
  ${(props) => (props.centerAlign ? 'margin: 0 auto !important;' : '')}

  span {
    font-family: 'GilroyBold', sans-serif;
    font-weight: 600;
    margin-left: 12px;
  }

  svg {
    height: 16px;
    float: ${(props) => (props.iconLeft ? 'left' : 'right')};
    min-width: auto !important;
    min-height: auto !important;
    ${(props) => (props.iconLeft ? 'margin-right' : 'margin-left')}: 12px;
    position: relative;
    top: 0px;

    @media screen and (min-width: 1900px) {
      top: 0px;
    }

    .st0 {
      fill: ${(props) => colorSmallCodesText[props.variant]};
    }
  }

  .rotateme {
    svg {
      transform: rotate(180deg);
    }
  }
`

export const PrimaryBigButton = ({
    children,
    variant = 'primary',
    paddingVariant = 'text',
    notFullWidth,
    centerAlign,
    handleClick = () => {},
    flipped,
    buttonVariant,
}) => {
    const paddingVariants = {
        text: BigButtonPadding,
        icon: BigButtonIconPadding,
    }

    const PaddingContainer = paddingVariants[paddingVariant]
    const Wrapper = buttonVariant
        ? PrimaryBigButtonWrapperBTN
        : PrimaryBigButtonWrapper
    return (
        <Wrapper
            centerAlign={centerAlign}
            variant={variant}
            notFullWidth={notFullWidth}
            onClick={handleClick}
            flipped={flipped}
        >
            <PaddingContainer>{children}</PaddingContainer>
        </Wrapper>
    )
}

export const ScrollButton = ({
    children,
    variant = 'primary',
    paddingVariant = 'text',
    notFullWidth,
    centerAlign,
    handleClick = () => {},
}) => {
    const paddingVariants = {
        text: ScrollButtonPadding,
        icon: BigButtonIconPadding,
    }

    const PaddingContainer = paddingVariants[paddingVariant]

    return (
        <PrimaryBigButtonWrapper
            centerAlign={centerAlign}
            variant={variant}
            notFullWidth={notFullWidth}
            onClick={handleClick}
        >
            <PaddingContainer>{children}</PaddingContainer>
        </PrimaryBigButtonWrapper>
    )
}

export const PrimarySmallButton = ({
    children,
    variant = 'primary',
    paddingVariant = 'small',
    notFullWidth,
    centerAlign,
    hideButton,
    handleClick,
    iconLeft,
}) => {
    const paddingVariants = {
        text: BigButtonPadding,
        icon: BigButtonIconPadding,
        small: SmallButtonPadding,
    }

    const PaddingContainer = paddingVariants[paddingVariant]

    return (
        <PrimarySmallButtonWrapper
            centerAlign={centerAlign}
            variant={variant}
            notFullWidth={notFullWidth}
            onClick={handleClick}
            iconLeft={iconLeft}
        >
            <PaddingContainer>
                {!hideButton && iconLeft && (
                    <span className="left rotateme">
                        <Chev />
                    </span>
                )}
                {children}{' '}
                {!hideButton && !iconLeft && (
                    <span>
                        {' '}
                        <Chev />{' '}
                    </span>
                )}
            </PaddingContainer>
        </PrimarySmallButtonWrapper>
    )
}
